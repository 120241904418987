import { Component, OnInit } from '@angular/core';
import { FormsService } from '../../services/forms.service';
import { ViewChild } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Observable, Subject } from 'rxjs'
import { map, startWith, takeUntil } from 'rxjs/operators'

import { ControlAttribute } from '../../attributes/control.attribute'
@Component({
  selector: 'cf-autocompelete',
  templateUrl: './autocompelete.component.html',
  styleUrls: ['./autocompelete.component.scss']
})
export class AutocompeleteComponent extends ControlAttribute implements OnInit {
  autocompleteOptions: string[] = []
  loading = true
  constructor(private formsService: FormsService) {
    super()
  }

  private _destroy$ = new Subject()

  public resource: Observable<string[]>

  @ViewChild('country', { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger

  ngOnInit(): void {
    this.formsService.listResource(this.control['field']['options']['url']).pipe(takeUntil(this._destroy$)).subscribe((res: string[]) => {
      this.loading = false
      this.autocompleteOptions = res
      this.resource = this.control.valueChanges.pipe(
        takeUntil(this._destroy$),
        startWith(''),
        map((value) => this.filterCountries(value))
      )
    })
  }

  get flag() {
    return 'assets/images/none.svg'
  }
  filterCountries(value: string) {
    value = value?.toLowerCase()
    if (!value) {
      return []
    }
    return this.autocompleteOptions.filter((o) => o.toLowerCase().includes(value) || o.toLowerCase().includes(value))
  }
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  ngAfterViewInit(): void {
    this.autoTrigger.panelClosingActions.pipe(takeUntil(this._destroy$)).subscribe(() => {
      if (this.autoTrigger.activeOption) {
        this.control.setValue(this.autoTrigger.activeOption.value)
        this.resource = null
        this.resource = this.control.valueChanges.pipe(
          takeUntil(this._destroy$),
          startWith(''),
          map((value) => this.filterCountries(value))
        )
      }
    })
  }

  onEnter(event: KeyboardEvent): void {
    event.preventDefault()
    event.stopPropagation()
  }
}
