import bwipjs from 'bwip-js'
import { settings } from '../../badge-drawer/settings'
import { getBadgeTemplate } from '../../badge-drawer'
import { RegistrationDocument } from '@app/modules/@micetribe/forms/models/registration-document.model'

export class BadgeDrawer {
  static coloredCodes = {
    wise2021: '#230000',
    'wise2021-ted': '#230000'
  }
  static diffFormatCodes = {
    wise2021: 'png',
    'wise2021-ted': 'png'
  }

  static getQrCodeDataFor(registration: RegistrationDocument) {
    let qrCodeData = registration.barcode,
      qrCodeDarkColor = '#000000ff',
      qrCodeFormat = 'svg'
    const templateName = this.getTemplateName(registration)

    if (templateName != 'default-badge') {
      if (Object.keys(this.coloredCodes).includes(templateName)) {
        qrCodeDarkColor = this.coloredCodes[templateName] || '#000'
      }
      if (Object.keys(this.diffFormatCodes).includes(templateName)) {
        qrCodeFormat = this.diffFormatCodes[templateName] || 'svg'
      }
    }

    return {
      content: qrCodeData,
      dark_color: qrCodeDarkColor,
      format: qrCodeFormat
    }
  }

  static getQrImgSrc(event: string | number | boolean) {
    let canvas = document.createElement('canvas')

    bwipjs.toCanvas(canvas, {
      bcid: 'qrcode',
      text: event.toString(),
      scale: 10,
      width: settings.qr.width * 3.7795275591
    })

    return canvas.toDataURL('image/png')
  }

  static getBarCodeDataFor(data) {
    let barcode_src

    let canvas = document.createElement('canvas') as HTMLCanvasElement

    bwipjs.toCanvas(canvas, {
      bcid: 'qrcode', // Barcode type for DTCM
      text: data.third_parts.dtcm.barcode, // Text to encode
      scale: 10, // 10x scaling factor
      height: settings.dtcm.height, // Bar height, in millimeters
      width: settings.dtcm.height // Barcode width in millimeters
    })

    barcode_src = canvas.toDataURL('image/png')

    return { barcode_src }
  }

  static getSampleBadge(registration: any, qrcode, settingsReplacement, fields: Object[]) {
    if (settingsReplacement.fields) {
      Object.keys(settingsReplacement.fields).map((field: string) => {
        if (!settings.fields.includes(field)) {
          settings.fields.push(field)
        }

        if (settings[field]) {
          Object.keys(settingsReplacement.fields[field]).map((key: string) => {
            settings[field][key] = settingsReplacement.fields[field][key]
          })
        } else {
          settings[field] = settingsReplacement.fields[field]
        }
      })
    }

    Object.keys(settingsReplacement).map((field: string) => {
      if (field == 'fields') return
      if (
        typeof settingsReplacement[field] === 'object' &&
        !Array.isArray(settingsReplacement[field]) &&
        settingsReplacement[field] !== null
      ) {
        if (settings[field]) {
          Object.keys(settingsReplacement[field]).map((key: string) => {
            settings[field][key] = settingsReplacement[field][key]
          })
        }
      } else {
        settings[field] = settingsReplacement[field]
      }
    })

    const templateName = this.getTemplateName(registration)

    return getBadgeTemplate(templateName, qrcode, fields)
  }

  static getBadgeFor(registration: RegistrationDocument | null, qrcode, settingsReplacement = null) {
    if (settingsReplacement && settingsReplacement.fields) {
      Object.keys(settingsReplacement.fields).map((field: string) => {
        if (!settings.fields.includes(field)) {
          settings.fields.push(field)
        }

        if (settings[field]) {
          Object.keys(settingsReplacement.fields[field]).map((key: string) => {
            settings[field][key] = settingsReplacement.fields[field][key]
          })
        } else {
          settings[field] = settingsReplacement.fields[field]
        }
      })
    }

    Object.keys(settingsReplacement).map((field: string) => {
      if (field == 'fields') return
      if (
        typeof settingsReplacement[field] === 'object' &&
        !Array.isArray(settingsReplacement[field]) &&
        settingsReplacement[field] !== null
      ) {
        if (settings[field]) {
          Object.keys(settingsReplacement[field]).map((key: string) => {
            settings[field][key] = settingsReplacement[field][key]
          })
        }
      } else {
        settings[field] = settingsReplacement[field]
      }
    })

    let fields = Object.keys(settingsReplacement.fields).map((key) => {
      return settingsReplacement.fields[key]
    })

    this.handlePrintinForGBEC(registration, fields)
    fields = fields.sort((a, b) => (a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0))

    const templateName = this.getTemplateName(registration)

    return getBadgeTemplate(templateName, qrcode, fields)
  }

  static getTemplateName(registration: RegistrationDocument | { event: string; workspace: string; form: string }) {
    let templateName = 'default-badge'

    if (!registration) {
      return templateName
    }

    if (registration.workspace === 'qf') {
      if (registration.event === 'wise2021' && registration.form !== 'generic') {
        if (registration.form === 'vis' || registration.form === 'vis0') {
          templateName = 'wise2021-ted'
        } else {
          templateName = 'wise2021'
        }
      } else if (registration.event === 'wise' && ['onsite-registration', 'import'].includes(registration.form)) {
        templateName = 'wise2023'
      }
    }

    if (registration.workspace == 'dmr' && registration.event == 'dbsya2em36k' && registration.form != 'generic') {
      templateName = 'wise2021'
    }

    if (
      (registration.workspace == 'dmr' && registration.event == 'tikitaka' && registration.form == 'vis') ||
      (registration.workspace == 'sharqvands' && registration.event == 'tikitaka' && registration.form == 'vis')
    ) {
      templateName = 'tikitaka'
    }

    if (
      (registration.workspace === 'dmr' && registration.event === 'gimsq23' && registration.form === 'vis') ||
      (registration.workspace === 'qbec' && registration.event === 'gimsq23' && ['ticket_members'].includes(registration.form))
    ) {
      templateName = 'geneva_international_motor_show_qatar'
    }

    if (
      (registration.workspace === 'dmr' && registration.event === 'gimsq23' && this.isCustomtBadge(registration)) ||
      (registration.workspace === 'qbec' &&
        registration.event === 'gimsq23' &&
        registration.event === 'gimsq23' &&
        this.isCustomtBadge(registration))
    ) {
      templateName = 'geneva_international_motor_show_qatar_custom_badge'
    }

    if (
      registration.workspace == 'qacreates' &&
      ['crrunway', 'crrunwayaab', 'crrunwayaabm38byke', 'crrunwayqm'].includes(registration.event)
    ) {
      templateName = 'qatar-fashion-united'
    }

    if (registration.workspace == 'agencepx' && registration.event == 'powerofinnovation' && registration.form == 'vis') {
      templateName = 'agencepx-powerofinnovation'
    }

    if (registration.workspace == 'udst' && registration.event == 'itas') {
      templateName = 'udst-itas'
    }

    if (
      registration.workspace.toLowerCase() == 'katara' &&
      registration.event.toLowerCase() == 'wahc23' &&
      ['seats', 'seats-8-december', 'seats-9-december', 'seats-copy'].includes(registration.form.toLowerCase())
    ) {
      templateName = 'katara-wahc23'
    }

    if (
      registration.workspace == 'qga' &&
      registration.event == 'cbqm27' &&
      [
        'qga-chalet',
        'qic',
        'qdf0',
        'rol0',
        'de-boer-chalet',
        'vodafone-for-qga-chaleta',
        'primepower-for-qga-chalet',
        'brandx-for-qga-chalet',
        'qph-for-qga-chalet',
        'rapid-access-for-qga-chalet'
      ].includes(registration.form)
    ) {
      templateName = 'qga_cbqm27'
    }

    if (
      registration.workspace.toLowerCase() == 'cxl' &&
      registration.event.toLowerCase() == 'cxlive2024' &&
      ['delegate', 'speaker'].includes(registration.form.toLowerCase())
    ) {
      templateName = 'customer_experience_live'
    }

    if (
      registration.workspace.toLowerCase() == 'cxl' &&
      registration.event.toLowerCase() == 'cxlive2024' &&
      registration.form.toLowerCase() == 'sponsor'
    ) {
      templateName = 'customer_experience_live_sponsor'
    }

    if (
      registration.workspace.toLowerCase() == 'via' &&
      registration.event.toLowerCase() == 'xct24' &&
      !['visitor', 'silvera', 'platinuma', 'generic'].includes(registration.form.toLowerCase())
    ) {
      templateName = 'via_sct24'
    }

    if(registration.workspace.toLowerCase() === 'dhe' && registration.event.toLowerCase() === 'dan') {
      templateName = 'dhedan_event_custom_bracelet'
    }

    if(registration.workspace.toLowerCase() === 'earthna' && registration.event.toLowerCase() === 'qnd24'){
      switch (registration.form.toLowerCase()) {
        case 'delegate':
          templateName = 'earthna_qnd24_delegate'
          break;
        case 'organiser':
          templateName = 'earthna_qnd24_organizer'
          break;
        case 'emc':
          templateName = 'earthna_qnd24_emc'
          break;
        case 'speaker':
          templateName = 'earthna_qnd24_speaker'
          break;
        case 'media':
          templateName = 'earthna_qnd24_media'
          break;
      }
    }
    if(registration.workspace.toLowerCase() === 'investqa' && registration.event.toLowerCase() === 'fs24'){
      templateName = 'investQa_fs24_delegates'
    }

    return templateName
  }

  static handlePrintinForGBEC(registration, fields) {
    if (
      ['dmr', 'qbec'].includes(registration.workspace) &&
      registration.event === 'gimsq23' &&
      !['vis', 'moi', 'protocol', 'ons0'].includes(registration.form)
    ) {
      fields.push({
        name: 'company',
        label: 'company',
        lineHeight: 4.5,
        ordinal: 0,
        enabled: true
      })
      if (!['media', 'med_members', 'moi', 'protocol'].includes(registration.form)) {
        registration.fields['company'] = registration.parent.fields['name']
      }
      if (registration.form === 'med_members') {
        registration.fields['company'] = registration.parent.fields['input']
      }
    }
  }

  static isCustomtBadge(registration): boolean {
    return [
      'med',
      'med_members',
      'spo',
      'spo_members',
      'vip',
      'vip_members',
      'org',
      'org_members',
      'exh',
      'exh_members',
      'sec',
      'sec_members',
      'svc',
      'svc_members',
      'cus',
      'cus_members',
      'moi',
      'protocol'
    ].includes(registration.form)
  }
}

