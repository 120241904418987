import { HttpEventType } from '@angular/common/http'
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { HeaderService } from '@app/modules/home/services/header.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { ControlAttribute } from '../../attributes/control.attribute'
import { UploadService } from '../../services/upload.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'cf-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent extends ControlAttribute implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject()
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  @ViewChild('uploadField') uploadField: ElementRef
  hasFile = false
  uploading = false
  uploadPercentage = 0
  isRotatingImage: boolean = false
  /**
   * Modal ref
   */
  modalRef: NgbModalRef

  constructor(
    private uploadService: UploadService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private headerService: HeaderService
  ) {
    super()
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.hasFile = !!value
    })
    this.hasFile = !!this.control.value
  }

  generateId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 8)
  }

  uploadFile(files: File[]) {
    if (!files[0]) return
    this.uploading = true
    this.hasFile = false
    this.uploadPercentage = 0
    const formSlug = JSON.parse(localStorage.getItem('crew-active-tab')).slug
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('workspace', this.headerService.workspaceSlug)
    formData.append('event', this.headerService.eventSlug)
    formData.append('form', formSlug)
    formData.append('element', this.control.field.name)
    formData.append('registration_id', this.generateId())

    this.uploadService.uploadFile(formData).subscribe(
      (resp: any) => {
        if (resp.type === HttpEventType.Response) {
          this.uploading = false
          this.hasFile = true
          this.control.setValue(resp.body.encoded_url)
          this.uploadPercentage = 0
        }
        if (resp.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * resp.loaded) / resp.total)
          this.uploadPercentage = percentDone
        }
        this.cdr.detectChanges()
      },
      (error) => {
        this.uploading = false
        this.hasFile = false
        this.uploadPercentage = 0
        this.toastr.error(error.error.error)
        this.cdr.detectChanges()
      }
    )
  }
  triggerFileSelect() {
    this.uploadField.nativeElement.click()
  }

  /**
   * Open profile picture modal
   * @param element ElementRef
   */
  openImage(element: ElementRef) {
    this.modalRef = this.modalService.open(element, { centered: true, windowClass: 'modal' })
  }

  /**
   * Close modal
   */
  close() {
    this.modalRef.close()
  }

  /**
   * Rotate the image
   */
  rotateImage() {
    if (this.isRotatingImage) return
    this.isRotatingImage = true
    const fileName = this.control.value.match(/.*\/(.+?)\./)[1]
    const element = fileName.split('-')[0]
    const registration = fileName.split('-')[1]
    const formSlug = this.control.value.match(/[\w\-\.]+/g)[5]
    const payload = {
      workspace: this.headerService.workspaceSlug,
      event: this.headerService.eventSlug,
      form: formSlug,
      element: element,
      registration_id: registration,
      angle: -90
    }
    this.uploadService.rotateFile(payload).pipe(takeUntil(this._destroy$)).subscribe(
      (res) => {
        setTimeout(() => {
          this.control.patchValue(`${res['url']}?${+new Date()}`)
          this.isRotatingImage = false
        }, 5000)
      },
      (err) => {
        this.toastr.error(err)
      }
    )
  }
  isImage(image): boolean {
    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'svg']
    const extension = image.split('.')?.pop()
    if (imageExtensions.includes(extension?.toLowerCase()) || this.customImportedData()) return true
    return false
  }
  customImportedData() {
    const CUSTOM_IMPORT_DATA = ['qf=>wise']
    return CUSTOM_IMPORT_DATA.includes(this.headerService.workspaceSlug.toLowerCase() + '=>' + this.headerService.eventSlug.toLowerCase())
  }
  openFile(path) {
    window.open(path, '_blank')
  }
}
