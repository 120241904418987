import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Observable, Subject } from 'rxjs'
import { map, startWith, takeUntil } from 'rxjs/operators'

import { ControlAttribute } from '../../attributes/control.attribute'
import { RepositoriesService } from '../../services/repositories.service'
@Component({
  selector: 'cf-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent extends ControlAttribute implements OnInit, OnDestroy, AfterViewInit {
  private _destroy$ = new Subject()

  public countries: Observable<{ name: string; flag: string; score: number }[]>

  @ViewChild('country', { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger
  constructor(public repoService: RepositoriesService) {
    super()
  }

  ngOnInit(): void {
    this.countries = this.control.valueChanges.pipe(
      takeUntil(this._destroy$),
      startWith(''),
      map((value) => this.filterCountries(value))
    )
  }
  get flag() {
    return (
      this.repoService.countries.find((o) => o.name.toLowerCase() === this.control.value?.toLowerCase())?.flag || 'assets/images/none.svg'
    )
  }
  filterCountries(value: string) {
    value = value?.toLowerCase()
    if (!value) {
      return []
    }
    return this.repoService.countries
      .filter((o) => o.name.toLowerCase().includes(value) || o.alternatives.toLowerCase().includes(value))
      .map((o) => {
        return {
          name: o.name,
          flag: o.flag,
          score: o.name.toLowerCase().startsWith(value) ? 1 : 0
        }
      })
      .sort((a, b) => b.score - a.score)
      .slice(0, 6)
  }

  onEnter(event: KeyboardEvent): void {
    event.preventDefault()
    event.stopPropagation()
  }

  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  ngAfterViewInit(): void {
    this.autoTrigger.panelClosingActions.pipe(takeUntil(this._destroy$)).subscribe(() => {
      if (this.autoTrigger.activeOption) {
        this.control.setValue(this.autoTrigger.activeOption.value)
        this.countries = null
        this.countries = this.control.valueChanges.pipe(
          takeUntil(this._destroy$),
          startWith(''),
          map((value) => this.filterCountries(value))
        )
      }
    })
  }
}
