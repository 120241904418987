import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { HeaderService } from '@app/modules/home/services/header.service'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { FormsService } from '@app/modules/@micetribe/forms/services/forms.service'
import { RegistrationDocument } from '@app/modules/@micetribe/forms/models/registration-document.model'
import { MiceForm } from '@app/modules/@micetribe/forms/models/form.model'
import { SearchService } from '@app/modules/@micetribe/search/services/search.service'
import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DtcmPrintWaitComponent } from '../dtcm-print-wait/dtcm-print-wait.component'

@Component({
  selector: 'mice-printing-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject()

  badgeData: RegistrationDocument
  badgeDataArr: RegistrationDocument[]
  badgeForm: MiceForm

  // a refreshing value to repush data to reprint
  print = 0

  constructor(
    private headerService: HeaderService,
    private cdr: ChangeDetectorRef,
    private forms: FormsService,
    private searchService: SearchService,
    private toastrService: ToastrService,
    private modal: NgbModal
  ) { }
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngOnInit(): void {
    this.modal.dismissAll()
    this.headerService.printDocument$
      .pipe(
        takeUntil(this._destroy$),
        filter((value) => !!value)
      )
      .subscribe((registration) => {
        this.cdr.detectChanges()
        registration.child_count
          ? this.printBadges(registration.members, this.forms.getForm(registration.form).subForms[0])
          : this.printBadge(registration, this.forms.getForm(registration.form))
      })
    this.headerService.printSearchResult$
      .pipe(
        takeUntil(this._destroy$),
        filter((value) => !!value)
      )
      .subscribe((result) => {
        const registrationsToPrint: RegistrationDocument[] = []
        this.cdr.detectChanges()
        if (result.result.form.parent && !result.self) {
          if (result.result.form.options.dtcm?.eventCode && result.result.form.options.dtcm.priceTypeCode && !result.result['_registration']['third_parts']['dtcm']['barcode'] && !result.result['_registration']['third_parts']['dtcm']['orderId']) {
            const modalRef = this.modal.open(DtcmPrintWaitComponent)
            this.forms.getDTCMEnabledRegistration(result.result['_registration']['parent_id']).subscribe(
              (reg) => {
                modalRef.close()
                this.headerService.updateRegisteration.next(reg)
                this.getGroupRegistrationAndUpdatePrintRecord(result, registrationsToPrint, false)
              },
              (error) => {
                modalRef.close()
                this.toastrService.error(error.error.error, 'DTCM issue')
              }
            )
            return
          }

          this.getGroupRegistrationAndUpdatePrintRecord(result, registrationsToPrint, false)
          return
        }
        if (!result.result.form.parent && result.result.form.subForms?.length) {
          if (result.result.form['haveSubForms'] && result.result.form['subForms'][0].options.dtcm?.eventCode && result.result.form['subForms'][0].options.dtcm.priceTypeCode) {
            const hasNotConnectedSubform = result.result.children.findIndex(child => !(child.registration.third_parts['dtcm']['barcode'] && child.registration.third_parts['dtcm']['orderId']))
            if (hasNotConnectedSubform >= 0) {
              const modalRef = this.modal.open(DtcmPrintWaitComponent)
              this.forms.getDTCMEnabledRegistration(result.result['_registration']['id']).subscribe(
                (reg) => {
                  modalRef.close()
                  this.getGroupRegistrationAndUpdatePrintRecord(result, registrationsToPrint)
                },
                (error) => {
                  modalRef.close()
                  this.toastrService.error(error.error.error, 'DTCM issue')
                }
              )
            } else {
              this.getGroupRegistrationAndUpdatePrintRecord(result, registrationsToPrint)
            }
            return
          }

          this.getGroupRegistrationAndUpdatePrintRecord(result, registrationsToPrint)
          return
        }
        this.printBadge(result.result.registration, result.result.form)
      })
  }

  getGroupRegistrationAndUpdatePrintRecord(result, registrationsToPrint, isParent = true) {
    const withParent = isParent ? 0 : 1
    this.searchService
      .getGroupRegistration({
        withParent,
        eventSlug: result.result.form.event.slug,
        id: isParent ? result.result.registration.id : result.result.registration.parent_id,
        workspaceSlug: this.headerService.workspaceSlug,
        perPage: isParent ? result.result.registration.child_count : result.result.registration.parent.child_count
      })
      .subscribe((res) => {
        registrationsToPrint.push(...res.data.children)
        this.printBadges(registrationsToPrint, isParent ? result.result.form.subForms[0] : result.result.form)
        this.forms.patchPrintRecord(isParent ? result.result.registration.id : result.result.registration.parent_id).subscribe(
          () => { },
          () => { }
        )
      })
  }

  /**
   * Fetches the badge template for the supplied form
   * and fills in the data supplied by the registration,
   * then prints the badge
   * @param registration RegistrationDocument[]
   * @param form MiceForm
   */
  printBadges(registrations: RegistrationDocument[], form: MiceForm) {
    this.badgeData = null
    this.badgeDataArr = registrations
    this.badgeForm = form
    ++this.print
  }
  /**
   * Checks if the registration needs DTCM barcode and fetches the
   * DTCM data before printing the card
   * @param registration RegistrationDocument
   * @param form MiceForm
   */
  printBadge(registration: RegistrationDocument, form: MiceForm) {
    if (!form.options?.dtcm?.section || (form.options?.dtcm?.section && registration.third_parts?.dtcm?.barcode)) {
      this.preparePrintDocument(registration, form)
      return
    }
    const modalRef = this.modal.open(DtcmPrintWaitComponent)
    this.forms.getDTCMEnabledRegistration(registration.id).subscribe(
      (reg) => {
        this.headerService.updateRegisteration.next(reg)
        this.preparePrintDocument(reg.document, form)
        modalRef.close()
      },
      (error) => {
        this.toastrService.error(error.error.error, 'DTCM issue')
        modalRef.close()
      }
    )
  }
  /**
   * Fetches the badge template for the supplied form
   * and fills in the data supplied by the registration,
   * then prints the badge
   * @param registration RegistrationDocument
   * @param form MiceForm
   */
  private preparePrintDocument(registration: RegistrationDocument, form: MiceForm) {
    this.badgeDataArr = null
    this.badgeData = registration
    this.badgeForm = form
    ++this.print
  }
}
