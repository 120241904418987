const domain = 'development.micetribe.com'
export const environment = {
  production: false,
  registrationsApi: `https://api.${domain}/v3/registrations/`,
  api: `https://api.${domain}`,
  appUrl: `https://crew.${domain}`,
  rtsWS: `wss://rts.${domain}/ws/`,
  mapbox_geocodig:'https://api.mapbox.com/geocoding/v5/',
  mapbox_access_token:'pk.eyJ1IjoiY29udGFjdGxlc3MiLCJhIjoiY2xobXpwMjJrMWFlcDNlb3h5aGh4bjJoOSJ9.ulKz7o1wPpEBk48Tz33BYw',
  googleMapsAPIKey: 'AIzaSyDORqT-hBGoHAhZNtQ0ZxGOsXZOxaijcRk',
  appVersion: 'prestaging.build-42'
}

import 'zone.js/dist/zone-error'

