export const titles = [
  'Lead',
  'Senior',
  'Direct',
  'Corporate',
  'Dynamic',
  'Future',
  'Product',
  'National',
  'Regional',
  'District',
  'Central',
  'Global',
  'Relational',
  'Customer',
  'Investor',
  'Dynamic',
  'International',
  'Legacy',
  'Forward',
  'Interactive',
  'Internal',
  'Human',
  'Chief',
  'Principal',
  'Solutions',
  'Program',
  'Brand',
  'Security',
  'Research',
  'Marketing',
  'Directives',
  'Implementation',
  'Integration',
  'Functionality',
  'Response',
  'Paradigm',
  'Tactics',
  'Identity',
  'Markets',
  'Group',
  'Resonance',
  'Applications',
  'Optimization',
  'Operations',
  'Infrastructure',
  'Intranet',
  'Communications',
  'Web',
  'Branding',
  'Quality',
  'Assurance',
  'Impact',
  'Mobility',
  'Ideation',
  'Data',
  'Creative',
  'Configuration',
  'Accountability',
  'Interactions',
  'Factors',
  'Usability',
  'Metrics',
  'Team',
  'Supervisor',
  'Associate',
  'Executive',
  'Liason',
  'Officer',
  'Manager',
  'Engineer',
  'Specialist',
  'Director',
  'Coordinator',
  'Administrator',
  'Architect',
  'Analyst',
  'Designer',
  'Planner',
  'Synergist',
  'Orchestrator',
  'Technician',
  'Developer',
  'Producer',
  'Consultant',
  'Assistant',
  'Facilitator',
  'Agent',
  'Representative',
  'Strategist',
  'LEAD',
  'SENIOR',
  'DIRECT',
  'CORPORATE',
  'DYNAMIC',
  'FUTURE',
  'PRODUCT',
  'NATIONAL',
  'REGIONAL',
  'DISTRICT',
  'CENTRAL',
  'GLOBAL',
  'RELATIONAL',
  'CUSTOMER',
  'INVESTOR',
  'DYNAMIC',
  'INTERNATIONAL',
  'LEGACY',
  'FORWARD',
  'INTERACTIVE',
  'INTERNAL',
  'HUMAN',
  'CHIEF',
  'PRINCIPAL',
  'SOLUTIONS',
  'PROGRAM',
  'BRAND',
  'SECURITY',
  'RESEARCH',
  'MARKETING',
  'DIRECTIVES',
  'IMPLEMENTATION',
  'INTEGRATION',
  'FUNCTIONALITY',
  'RESPONSE',
  'PARADIGM',
  'TACTICS',
  'IDENTITY',
  'MARKETS',
  'GROUP',
  'RESONANCE',
  'APPLICATIONS',
  'OPTIMIZATION',
  'OPERATIONS',
  'INFRASTRUCTURE',
  'INTRANET',
  'COMMUNICATIONS',
  'WEB',
  'BRANDING',
  'QUALITY',
  'ASSURANCE',
  'IMPACT',
  'MOBILITY',
  'IDEATION',
  'DATA',
  'CREATIVE',
  'CONFIGURATION',
  'ACCOUNTABILITY',
  'INTERACTIONS',
  'FACTORS',
  'USABILITY',
  'METRICS',
  'TEAM',
  'SUPERVISOR',
  'ASSOCIATE',
  'EXECUTIVE',
  'LIASON',
  'OFFICER',
  'MANAGER',
  'ENGINEER',
  'SPECIALIST',
  'DIRECTOR',
  'COORDINATOR',
  'ADMINISTRATOR',
  'ARCHITECT',
  'ANALYST',
  'DESIGNER',
  'PLANNER',
  'SYNERGIST',
  'ORCHESTRATOR',
  'TECHNICIAN',
  'DEVELOPER',
  'PRODUCER',
  'CONSULTANT',
  'ASSISTANT',
  'FACILITATOR',
  'AGENT',
  'REPRESENTATIVE',
  'STRATEGIST',
]

