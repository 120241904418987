export const investQa_fs24_delegates = (settings, qrcode) => ({
  card: `
    <div class="badge-container">
    <div class="info-container">
        <div class="wrapper">
            <div>
            <p class="fullname">#_INPUT_#</p>
            </div>
            <div>
            <p class="fullname">#_INPUT1_#</p>
            </div>
        </div>
    </div>
</div>
    `
  ,
  style: `
      <style>
      * {
      font-family: 'Frutiger', sans-serif;
      border-radius: 0;
      margin: 0;
      padding: 0;
      font-weight: 400;
      visibility: visible;
      color: #000;
  }
  
  .wrapper {
      padding: 0 2mm;
      height: 52mm;
      overflow: hidden;
      width:100%
    }
  
  .mb-2 {
      margin-bottom: 2px !important;
  }
  
  .badge-container {
      width: 81mm !important;
      height: 126mm !important;
      margin-right: auto !important;
      margin-left: auto !important;
      text-align: center !important;
      transform: rotateZ(180deg) !important;
  }
  
  .info-container {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 47mm !important;
  }
  
  
  
  .fullname {
      padding-top: 2mm;
      font-size: 9mm;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: capitalize;
  }
  
  .print-page {
      display: block
  }
  
  .page-break {
      display: block;
      page-break-before: always
  }
  
  @media print {
      .page-break {
          display: block;
          page-break-before: always
      }
  }
    @font-face {
        font-family: 'Frutiger';
        src: url('https://library.micetribe.com/fonts/Frutiger+45+Light.otf') format('truetype');
    }
      </style>
      `
})
