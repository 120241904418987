import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class RepositoriesService {
  constructor() {}
  public countries = [
    {
      id: 1,
      name: 'Afghanistan',
      localname: '',
      alternatives: 'Afghan, Afgani, Afganistani',
      iso2: 'AF',
      iso3: 'AFG',
      code: 'AF',
      ison: 4,
      e164: 93,
      fips: 'AF',
      tld: 'af',
      area: 647500,
      phone_code: '93',
      continent: 'Asia',
      capital: 'Kabul',
      capital_timezone: 'Asia/Kabul',
      currency: 'Afghani',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/afg.png'
    },
    {
      id: 2,
      name: 'Albania',
      localname: '',
      alternatives: 'Albanian',
      code: 'AL',
      iso2: 'AL',
      iso3: 'ALB',
      ison: 8,
      e164: 355,
      fips: 'AL',
      tld: 'al',
      area: 28748,
      phone_code: '355',
      continent: 'Europe',
      capital: 'Tirana',
      capital_timezone: 'Europe/Tirane',
      currency: 'Lek',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/alb.png'
    },
    {
      id: 3,
      name: 'Algeria',
      localname: '',
      alternatives: 'Algerian',
      iso2: 'DZ',
      iso3: 'DZA',
      code: 'DZ',
      ison: 12,
      e164: 213,
      fips: 'AG',
      tld: 'dz',
      area: 2381740,
      phone_code: '213',
      continent: 'Africa',
      capital: 'Algiers',
      capital_timezone: 'Africa/Algiers',
      currency: 'Dinar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/dza.png'
    },
    {
      id: 4,
      name: 'American Samoa',
      localname: '',
      alternatives: 'American Samoan',
      code: 'AS',
      iso2: 'AS',
      iso3: 'ASM',
      ison: 16,
      e164: 1,
      fips: 'AQ',
      tld: 'as',
      area: 199,
      phone_code: '1-684',
      continent: 'Oceania',
      capital: 'Pago Pago',
      capital_timezone: 'Pacific/Pago_Pago',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/asm.png'
    },
    {
      id: 5,
      name: 'Andorra',
      localname: '',
      alternatives: 'Andorran',
      code: 'AD',
      iso2: 'AD',
      iso3: 'AND',
      ison: 20,
      e164: 376,
      fips: 'AN',
      tld: 'ad',
      area: 468,
      phone_code: '376',
      continent: 'Europe',
      capital: 'Andorra la Vella',
      capital_timezone: 'Europe/Andorra',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/and.png'
    },
    {
      id: 6,
      name: 'Angola',
      localname: '',
      alternatives: 'Angolan',
      code: 'AO',
      iso2: 'AO',
      iso3: 'AGO',
      ison: 24,
      e164: 244,
      fips: 'AO',
      tld: 'ao',
      area: 1246700,
      phone_code: '244',
      continent: 'Africa',
      capital: 'Luanda',
      capital_timezone: 'Africa/Luanda',
      currency: 'Kwanza',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ago.png'
    },
    {
      id: 7,
      name: 'Anguilla',
      localname: '',
      alternatives: 'Anguillan',
      code: 'AI',
      iso2: 'AI',
      iso3: 'AIA',
      ison: 660,
      e164: 1,
      fips: 'AV',
      tld: 'ai',
      area: 102,
      phone_code: '1-264',
      continent: 'North America',
      capital: 'The Valley',
      capital_timezone: 'America/Anguilla',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/aia.png'
    },
    {
      id: 8,
      name: 'Antarctica',
      localname: '',
      alternatives: 'Antarctic',
      code: 'AQ',
      iso2: 'AQ',
      iso3: 'ATA',
      ison: 10,
      e164: 672,
      fips: 'AY',
      tld: 'aq',
      area: 14000000,
      phone_code: '',
      continent: 'Antarctica',
      capital: '',
      capital_timezone: 'Antarctica/Troll',
      currency: '',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ata.png'
    },
    {
      id: 9,
      name: 'Antigua and Barbuda',
      localname: '',
      alternatives: 'Antiguan, Barbudan',
      code: 'AG',
      iso2: 'AG',
      iso3: 'ATG',
      ison: 28,
      e164: 1,
      fips: 'AC',
      tld: 'ag',
      area: 443,
      phone_code: '1-268',
      continent: 'North America',
      capital: "St. John's",
      capital_timezone: 'America/Antigua',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/atg.png'
    },
    {
      id: 10,
      name: 'Argentina',
      localname: '',
      alternatives: 'Argentine, Argentinian',
      code: 'AR',
      iso2: 'AR',
      iso3: 'ARG',
      ison: 32,
      e164: 54,
      fips: 'AR',
      tld: 'ar',
      area: 2766890,
      phone_code: '54',
      continent: 'South America',
      capital: 'Buenos Aires',
      capital_timezone: 'America/Argentina/Buenos_Aires',
      currency: 'Peso',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/arg.png'
    },
    {
      id: 11,
      name: 'Armenia',
      localname: '',
      alternatives: 'Armenian',
      code: 'AM',
      iso2: 'AM',
      iso3: 'ARM',
      ison: 51,
      e164: 374,
      fips: 'AM',
      tld: 'am',
      area: 29800,
      phone_code: '374',
      continent: 'Asia',
      capital: 'Yerevan',
      capital_timezone: 'Asia/Yerevan',
      currency: 'Dram',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/arm.png'
    },
    {
      id: 12,
      name: 'Aruba',
      localname: '',
      alternatives: 'Aruban',
      code: 'AW',
      iso2: 'AW',
      iso3: 'ABW',
      ison: 533,
      e164: 297,
      fips: 'AA',
      tld: 'aw',
      area: 193,
      phone_code: '297',
      continent: 'North America',
      capital: 'Oranjestad',
      capital_timezone: 'America/Aruba',
      currency: 'Guilder',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/abw.png'
    },
    {
      id: 13,
      name: 'Australia',
      localname: '',
      alternatives: 'Australian, Aussie',
      code: 'AU',
      iso2: 'AU',
      iso3: 'AUS',
      ison: 36,
      e164: 61,
      fips: 'AS',
      tld: 'au',
      area: 7686850,
      phone_code: '61',
      continent: 'Oceania',
      capital: 'Canberra',
      capital_timezone: 'Australia/Sydney',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/aus.png'
    },
    {
      id: 14,
      name: 'Austria',
      localname: '',
      alternatives: 'Austrian',
      code: 'AT',
      iso2: 'AT',
      iso3: 'AUT',
      ison: 40,
      e164: 43,
      fips: 'AU',
      tld: 'at',
      area: 83858,
      phone_code: '43',
      continent: 'Europe',
      capital: 'Vienna',
      capital_timezone: 'Europe/Vienna',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/aut.png'
    },
    {
      id: 15,
      name: 'Azerbaijan',
      localname: '',
      alternatives: 'Azerbaijani, Azeri',
      code: 'AZ',
      iso2: 'AZ',
      iso3: 'AZE',
      ison: 31,
      e164: 994,
      fips: 'AJ',
      tld: 'az',
      area: 86600,
      phone_code: '994',
      continent: 'Asia',
      capital: 'Baku',
      capital_timezone: 'Asia/Baku',
      currency: 'Manat',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/aze.png'
    },
    {
      id: 16,
      name: 'Bahamas',
      localname: '',
      alternatives: 'Bahamian',
      code: 'BS',
      iso2: 'BS',
      iso3: 'BHS',
      ison: 44,
      e164: 1,
      fips: 'BF',
      tld: 'bs',
      area: 13940,
      phone_code: '1-242',
      continent: 'North America',
      capital: 'Nassau',
      capital_timezone: 'America/Nassau',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bhs.png'
    },
    {
      id: 17,
      name: 'Bahrain',
      localname: '',
      alternatives: 'Bahraini',
      code: 'BH',
      iso2: 'BH',
      iso3: 'BHR',
      ison: 48,
      e164: 973,
      fips: 'BA',
      tld: 'bh',
      area: 665,
      phone_code: '973',
      continent: 'Asia',
      capital: 'Manama',
      capital_timezone: 'Asia/Bahrain',
      currency: 'Dinar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bhr.png'
    },
    {
      id: 18,
      name: 'Bangladesh',
      localname: '',
      alternatives: 'Bengali, Bangladeshi',
      code: 'BD',
      iso2: 'BD',
      iso3: 'BGD',
      ison: 50,
      e164: 880,
      fips: 'BG',
      tld: 'bd',
      area: 144000,
      phone_code: '880',
      continent: 'Asia',
      capital: 'Dhaka',
      capital_timezone: 'Asia/Dhaka',
      currency: 'Taka',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bgd.png'
    },
    {
      id: 19,
      name: 'Barbados',
      localname: '',
      alternatives: 'Barbadian',
      code: 'BB',
      iso2: 'BB',
      iso3: 'BRB',
      ison: 52,
      e164: 1,
      fips: 'BB',
      tld: 'bb',
      area: 431,
      phone_code: '1-246',
      continent: 'North America',
      capital: 'Bridgetown',
      capital_timezone: 'America/Barbados',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/brb.png'
    },
    {
      id: 20,
      name: 'Belarus',
      localname: '',
      alternatives: 'Belarusian',
      code: 'BY',
      iso2: 'BY',
      iso3: 'BLR',
      ison: 112,
      e164: 375,
      fips: 'BO',
      tld: 'by',
      area: 207600,
      phone_code: '375',
      continent: 'Europe',
      capital: 'Minsk',
      capital_timezone: 'Europe/Minsk',
      currency: 'Ruble',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/blr.png'
    },
    {
      id: 21,
      name: 'Belgium',
      localname: '',
      alternatives: 'Belgian',
      iso2: 'BE',
      code: 'BE',
      iso3: 'BEL',
      ison: 56,
      e164: 32,
      fips: 'BE',
      tld: 'be',
      area: 30510,
      phone_code: '32',
      continent: 'Europe',
      capital: 'Brussels',
      capital_timezone: 'Europe/Brussels',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bel.png'
    },
    {
      id: 22,
      name: 'Belize',
      localname: '',
      alternatives: 'Belizean',
      code: 'BZ',
      iso2: 'BZ',
      iso3: 'BLZ',
      ison: 84,
      e164: 501,
      fips: 'BH',
      tld: 'bz',
      area: 22966,
      phone_code: '501',
      continent: 'North America',
      capital: 'Belmopan',
      capital_timezone: 'America/Belize',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/blz.png'
    },
    {
      id: 23,
      name: 'Benin',
      localname: '',
      alternatives: 'Beninese, Beninois',
      code: 'BJ',
      iso2: 'BJ',
      iso3: 'BEN',
      ison: 204,
      e164: 229,
      fips: 'BN',
      tld: 'bj',
      area: 112620,
      phone_code: '229',
      continent: 'Africa',
      capital: 'Porto-Novo',
      capital_timezone: 'Africa/Porto-Novo',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ben.png'
    },
    {
      id: 24,
      name: 'Bermuda',
      localname: '',
      alternatives: 'Bermudian, Bermudan',
      iso2: 'BM',
      iso3: 'BMU',
      ison: 60,
      e164: 1,
      fips: 'BD',
      tld: 'bm',
      area: 53,
      phone_code: '1-441',
      continent: 'North America',
      capital: 'Hamilton',
      capital_timezone: 'Atlantic/Bermuda',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bmu.png'
    },
    {
      id: 25,
      name: 'Bhutan',
      localname: '',
      alternatives: 'Bhutanese',
      iso2: 'BT',
      iso3: 'BTN',
      ison: 64,
      e164: 975,
      fips: 'BT',
      tld: 'bt',
      area: 47000,
      phone_code: '975',
      continent: 'Asia',
      capital: 'Thimphu',
      capital_timezone: 'Asia/Thimphu',
      currency: 'Ngultrum',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/btn.png'
    },
    {
      id: 26,
      name: 'Bolivia',
      localname: '',
      alternatives: 'Bolivian',
      iso2: 'BO',
      iso3: 'BOL',
      ison: 68,
      e164: 591,
      fips: 'BL',
      tld: 'bo',
      area: 1098580,
      phone_code: '591',
      continent: 'South America',
      capital: 'Sucre',
      capital_timezone: 'America/La_Paz',
      currency: 'Boliviano',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bol.png'
    },
    {
      id: 27,
      name: 'Bosnia and Herzegovina',
      localname: '',
      alternatives: 'Bosnian, Herzegovinian',
      iso2: 'BA',
      iso3: 'BIH',
      ison: 70,
      e164: 387,
      fips: 'BK',
      tld: 'ba',
      area: 51129,
      phone_code: '387',
      continent: 'Europe',
      capital: 'Sarajevo',
      capital_timezone: 'Europe/Sarajevo',
      currency: 'Marka',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bih.png'
    },
    {
      id: 28,
      name: 'Botswana',
      localname: '',
      alternatives: 'Motswana, Botswanan',
      iso2: 'BW',
      iso3: 'BWA',
      ison: 72,
      e164: 267,
      fips: 'BC',
      tld: 'bw',
      area: 600370,
      phone_code: '267',
      continent: 'Africa',
      capital: 'Gaborone',
      capital_timezone: 'Africa/Gaborone',
      currency: 'Pula',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bwa.png'
    },
    {
      id: 29,
      name: 'Brazil',
      localname: '',
      alternatives: 'Brazilian',
      iso2: 'BR',
      iso3: 'BRA',
      ison: 76,
      e164: 55,
      fips: 'BR',
      tld: 'br',
      area: 8511965,
      phone_code: '55',
      continent: 'South America',
      capital: 'Brasilia',
      capital_timezone: 'America/Sao_Paulo',
      currency: 'Real',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bra.png'
    },
    {
      id: 30,
      name: 'British Indian Ocean Territory',
      localname: '',
      alternatives: 'BIOT, British',
      iso2: 'IO',
      iso3: 'IOT',
      ison: 86,
      e164: 246,
      fips: 'IO',
      tld: 'io',
      area: 60,
      phone_code: '246',
      continent: 'Asia',
      capital: 'Diego Garcia',
      capital_timezone: 'Indian/Chagos',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/iot.png'
    },
    {
      id: 31,
      name: 'British Virgin Islands',
      localname: '',
      alternatives: 'British Virgin Islanders',
      iso2: 'VG',
      iso3: 'VGB',
      ison: 92,
      e164: 1,
      fips: 'VI',
      tld: 'vg',
      area: 153,
      phone_code: '1-284',
      continent: 'North America',
      capital: 'Road Town',
      capital_timezone: 'America/Tortola',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/vgb.png'
    },
    {
      id: 32,
      name: 'Brunei',
      localname: '',
      alternatives: 'Bruneian',
      iso2: 'BN',
      iso3: 'BRN',
      ison: 96,
      e164: 673,
      fips: 'BX',
      tld: 'bn',
      area: 5770,
      phone_code: '673',
      continent: 'Asia',
      capital: 'Bandar Seri Begawan',
      capital_timezone: 'Asia/Brunei',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/brn.png'
    },
    {
      id: 33,
      name: 'Bulgaria',
      localname: '',
      alternatives: 'Bulgarian',
      iso2: 'BG',
      iso3: 'BGR',
      ison: 100,
      e164: 359,
      fips: 'BU',
      tld: 'bg',
      area: 110910,
      phone_code: '359',
      continent: 'Europe',
      capital: 'Sofia',
      capital_timezone: 'Europe/Sofia',
      currency: 'Lev',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bgr.png'
    },
    {
      id: 34,
      name: 'Burkina Faso',
      localname: '',
      alternatives: 'Burkinabe',
      iso2: 'BF',
      iso3: 'BFA',
      ison: 854,
      e164: 226,
      fips: 'UV',
      tld: 'bf',
      area: 274200,
      phone_code: '226',
      continent: 'Africa',
      capital: 'Ouagadougou',
      capital_timezone: 'Africa/Ouagadougou',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bfa.png'
    },
    {
      id: 35,
      name: 'Burundi',
      localname: '',
      alternatives: 'Burundian',
      iso2: 'BI',
      iso3: 'BDI',
      ison: 108,
      e164: 257,
      fips: 'BY',
      tld: 'bi',
      area: 27830,
      phone_code: '257',
      continent: 'Africa',
      capital: 'Bujumbura',
      capital_timezone: 'Africa/Bujumbura',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/bdi.png'
    },
    {
      id: 36,
      name: 'Cambodia',
      localname: '',
      alternatives: 'Cambodian',
      iso2: 'KH',
      iso3: 'KHM',
      ison: 116,
      e164: 855,
      fips: 'CB',
      tld: 'kh',
      area: 181040,
      phone_code: '855',
      continent: 'Asia',
      capital: 'Phnom Penh',
      capital_timezone: 'Asia/Phnom_Penh',
      currency: 'Riels',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/khm.png'
    },
    {
      id: 37,
      name: 'Cameroon',
      localname: '',
      alternatives: 'Cameroonian',
      iso2: 'CM',
      iso3: 'CMR',
      ison: 120,
      e164: 237,
      fips: 'CM',
      tld: 'cm',
      area: 475440,
      phone_code: '237',
      continent: 'Africa',
      capital: 'Yaounde',
      capital_timezone: 'Africa/Douala',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cmr.png'
    },
    {
      id: 38,
      name: 'Canada',
      localname: '',
      alternatives: 'Canadian',
      iso2: 'CA',
      iso3: 'CAN',
      ison: 124,
      e164: 1,
      fips: 'CA',
      tld: 'ca',
      area: 9984670,
      phone_code: '1',
      continent: 'North America',
      capital: 'Ottawa',
      capital_timezone: 'America/Toronto',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/can.png'
    },
    {
      id: 39,
      name: 'Cape Verde',
      localname: '',
      alternatives: 'Cabo Verdean',
      iso2: 'CV',
      iso3: 'CPV',
      ison: 132,
      e164: 238,
      fips: 'CV',
      tld: 'cv',
      area: 4033,
      phone_code: '238',
      continent: 'Africa',
      capital: 'Praia',
      capital_timezone: 'Atlantic/Cape_Verde',
      currency: 'Escudo',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cpv.png'
    },
    {
      id: 40,
      name: 'Cayman Islands',
      localname: '',
      alternatives: 'Caymanian',
      iso2: 'KY',
      iso3: 'CYM',
      ison: 136,
      e164: 1,
      fips: 'CJ',
      tld: 'ky',
      area: 262,
      phone_code: '1-345',
      continent: 'North America',
      capital: 'George Town',
      capital_timezone: 'America/Cayman',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cym.png'
    },
    {
      id: 41,
      name: 'Central African Republic',
      localname: '',
      alternatives: 'Central African',
      iso2: 'CF',
      iso3: 'CAF',
      ison: 140,
      e164: 236,
      fips: 'CT',
      tld: 'cf',
      area: 622984,
      phone_code: '236',
      continent: 'Africa',
      capital: 'Bangui',
      capital_timezone: 'Africa/Bangui',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/caf.png'
    },
    {
      id: 42,
      name: 'Chad',
      localname: '',
      alternatives: 'Chadian',
      iso2: 'TD',
      iso3: 'TCD',
      ison: 148,
      e164: 235,
      fips: 'CD',
      tld: 'td',
      area: 1284000,
      phone_code: '235',
      continent: 'Africa',
      capital: "N'Djamena",
      capital_timezone: 'Africa/Ndjamena',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tcd.png'
    },
    {
      id: 43,
      name: 'Chile',
      localname: '',
      alternatives: 'Chilean',
      iso2: 'CL',
      iso3: 'CHL',
      ison: 152,
      e164: 56,
      fips: 'CI',
      tld: 'cl',
      area: 756950,
      phone_code: '56',
      continent: 'South America',
      capital: 'Santiago',
      capital_timezone: 'America/Santiago',
      currency: 'Peso',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/chl.png'
    },
    {
      id: 44,
      name: 'China',
      localname: '',
      alternatives: 'Chinese',
      iso2: 'CN',
      iso3: 'CHN',
      ison: 156,
      e164: 86,
      fips: 'CH',
      tld: 'cn',
      area: 9596960,
      phone_code: '86',
      continent: 'Asia',
      capital: 'Beijing',
      capital_timezone: 'Asia/Shanghai',
      currency: 'Yuan Renminbi',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/chn.png'
    },
    {
      id: 45,
      name: 'Christmas Island',
      localname: '',
      alternatives: 'Christmas Islanders',
      iso2: 'CX',
      iso3: 'CXR',
      ison: 162,
      e164: 61,
      fips: 'KT',
      tld: 'cx',
      area: 135,
      phone_code: '61',
      continent: 'Asia',
      capital: 'Flying Fish Cove',
      capital_timezone: 'Indian/Christmas',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cxr.png'
    },
    {
      id: 46,
      name: 'Cocos Islands',
      localname: '',
      alternatives: 'Cocos Islanders',
      iso2: 'CC',
      iso3: 'CCK',
      ison: 166,
      e164: 61,
      fips: 'CK',
      tld: 'cc',
      area: 14,
      phone_code: '61',
      continent: 'Asia',
      capital: 'West Island',
      capital_timezone: 'Indian/Cocos',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cck.png'
    },
    {
      id: 47,
      name: 'Colombia',
      localname: '',
      alternatives: 'Colombian',
      iso2: 'CO',
      iso3: 'COL',
      ison: 170,
      e164: 57,
      fips: 'CO',
      tld: 'co',
      area: 1138910,
      phone_code: '57',
      continent: 'South America',
      capital: 'Bogota',
      capital_timezone: 'America/Bogota',
      currency: 'Peso',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/col.png'
    },
    {
      id: 48,
      name: 'Comoros',
      localname: '',
      alternatives: 'Comoran, Comorian',
      iso2: 'KM',
      iso3: 'COM',
      ison: 174,
      e164: 269,
      fips: 'CN',
      tld: 'km',
      area: 2170,
      phone_code: '269',
      continent: 'Africa',
      capital: 'Moroni',
      capital_timezone: 'Indian/Comoro',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/com.png'
    },
    {
      id: 49,
      name: 'Cook Islands',
      localname: '',
      alternatives: 'Cook Islanders',
      iso2: 'CK',
      iso3: 'COK',
      ison: 184,
      e164: 682,
      fips: 'CW',
      tld: 'ck',
      area: 240,
      phone_code: '682',
      continent: 'Oceania',
      capital: 'Avarua',
      capital_timezone: 'Pacific/Rarotonga',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cok.png'
    },
    {
      id: 50,
      name: 'Costa Rica',
      localname: '',
      alternatives: 'Costa Rican',
      iso2: 'CR',
      iso3: 'CRI',
      ison: 188,
      e164: 506,
      fips: 'CS',
      tld: 'cr',
      area: 51100,
      phone_code: '506',
      continent: 'North America',
      capital: 'San Jose',
      capital_timezone: 'America/Costa_Rica',
      currency: 'Colon',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cri.png'
    },
    {
      id: 51,
      name: 'Croatia',
      localname: '',
      alternatives: 'Croatian',
      iso2: 'HR',
      iso3: 'HRV',
      ison: 191,
      e164: 385,
      fips: 'HR',
      tld: 'hr',
      area: 56542,
      phone_code: '385',
      continent: 'Europe',
      capital: 'Zagreb',
      capital_timezone: 'Europe/Zagreb',
      currency: 'Kuna',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/hrv.png'
    },
    {
      id: 52,
      name: 'Cuba',
      localname: '',
      alternatives: 'Cuban',
      iso2: 'CU',
      iso3: 'CUB',
      ison: 192,
      e164: 53,
      fips: 'CU',
      tld: 'cu',
      area: 110860,
      phone_code: '53',
      continent: 'North America',
      capital: 'Havana',
      capital_timezone: 'America/Havana',
      currency: 'Peso',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cub.png'
    },
    {
      id: 53,
      name: 'Cyprus',
      localname: '',
      alternatives: 'Cypriot',
      iso2: 'CY',
      iso3: 'CYP',
      ison: 196,
      e164: 357,
      fips: 'CY',
      tld: 'cy',
      area: 9250,
      phone_code: '357',
      continent: 'Europe',
      capital: 'Nicosia',
      capital_timezone: 'Asia/Nicosia',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cyp.png'
    },
    {
      id: 54,
      name: 'Czech Republic',
      localname: '',
      alternatives: 'Czechia',
      iso2: 'CZ',
      iso3: 'CZE',
      ison: 203,
      e164: 420,
      fips: 'EZ',
      tld: 'cz',
      area: 78866,
      phone_code: '420',
      continent: 'Europe',
      capital: 'Prague',
      capital_timezone: 'Europe/Prague',
      currency: 'Koruna',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cze.png'
    },
    {
      id: 55,
      name: 'Democratic Republic of the Congo',
      localname: '',
      alternatives: 'Congolese',
      iso2: 'CD',
      iso3: 'COD',
      ison: 180,
      e164: 243,
      fips: 'CG',
      tld: 'cd',
      area: 2345410,
      phone_code: '243',
      continent: 'Africa',
      capital: 'Kinshasa',
      capital_timezone: 'Africa/Kinshasa',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cod.png'
    },
    {
      id: 56,
      name: 'Denmark',
      localname: '',
      alternatives: 'Danish',
      iso2: 'DK',
      iso3: 'DNK',
      ison: 208,
      e164: 45,
      fips: 'DA',
      tld: 'dk',
      area: 43094,
      phone_code: '45',
      continent: 'Europe',
      capital: 'Copenhagen',
      capital_timezone: 'Europe/Copenhagen',
      currency: 'Krone',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/dnk.png'
    },
    {
      id: 57,
      name: 'Djibouti',
      localname: '',
      alternatives: 'Djiboutian',
      iso2: 'DJ',
      iso3: 'DJI',
      ison: 262,
      e164: 253,
      fips: 'DJ',
      tld: 'dj',
      area: 23000,
      phone_code: '253',
      continent: 'Africa',
      capital: 'Djibouti',
      capital_timezone: 'Africa/Djibouti',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/dji.png'
    },
    {
      id: 58,
      name: 'Dominica',
      localname: '',
      alternatives: 'Dominican',
      iso2: 'DM',
      iso3: 'DMA',
      ison: 212,
      e164: 1,
      fips: 'DO',
      tld: 'dm',
      area: 754,
      phone_code: '1-767',
      continent: 'North America',
      capital: 'Roseau',
      capital_timezone: 'America/Dominica',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/dma.png'
    },
    {
      id: 59,
      name: 'Dominican Republic',
      localname: '',
      alternatives: 'Dominican',
      iso2: 'DO',
      iso3: 'DOM',
      ison: 214,
      e164: 1,
      fips: 'DR',
      tld: 'do',
      area: 48730,
      phone_code: '1-809 and ',
      continent: 'North America',
      capital: 'Santo Domingo',
      capital_timezone: 'America/Santo_Domingo',
      currency: 'Peso',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/dom.png'
    },
    {
      id: 60,
      name: 'East Timor',
      localname: '',
      alternatives: 'Timorese',
      iso2: 'TL',
      iso3: 'TLS',
      ison: 626,
      e164: 670,
      fips: 'TT',
      tld: 'tl',
      area: 15007,
      phone_code: '670',
      continent: 'Oceania',
      capital: 'Dili',
      capital_timezone: 'Asia/Dili',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tls.png'
    },
    {
      id: 61,
      name: 'Ecuador',
      localname: '',
      alternatives: 'Ecuadorian',
      iso2: 'EC',
      iso3: 'ECU',
      ison: 218,
      e164: 593,
      fips: 'EC',
      tld: 'ec',
      area: 283560,
      phone_code: '593',
      continent: 'South America',
      capital: 'Quito',
      capital_timezone: 'America/Guayaquil',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ecu.png'
    },
    {
      id: 62,
      name: 'Egypt',
      localname: '',
      alternatives: 'Egyptian',
      iso2: 'EG',
      iso3: 'EGY',
      ison: 818,
      e164: 20,
      fips: 'EG',
      tld: 'eg',
      area: 1001450,
      phone_code: '20',
      continent: 'Africa',
      capital: 'Cairo',
      capital_timezone: 'Africa/Cairo',
      currency: 'Pound',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/egy.png'
    },
    {
      id: 63,
      name: 'El Salvador',
      localname: '',
      alternatives: 'Salvadoran',
      iso2: 'SV',
      iso3: 'SLV',
      ison: 222,
      e164: 503,
      fips: 'ES',
      tld: 'sv',
      area: 21040,
      phone_code: '503',
      continent: 'North America',
      capital: 'San Salvador',
      capital_timezone: 'America/El_Salvador',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/slv.png'
    },
    {
      id: 64,
      name: 'Equatorial Guinea',
      localname: '',
      alternatives: 'Equatorial Guinean, Equatoguinean',
      iso2: 'GQ',
      iso3: 'GNQ',
      ison: 226,
      e164: 240,
      fips: 'EK',
      tld: 'gq',
      area: 28051,
      phone_code: '240',
      continent: 'Africa',
      capital: 'Malabo',
      capital_timezone: 'Africa/Malabo',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gnq.png'
    },
    {
      id: 65,
      name: 'Eritrea',
      localname: '',
      alternatives: 'Eritrean',
      iso2: 'ER',
      iso3: 'ERI',
      ison: 232,
      e164: 291,
      fips: 'ER',
      tld: 'er',
      area: 121320,
      phone_code: '291',
      continent: 'Africa',
      capital: 'Asmara',
      capital_timezone: 'Africa/Asmara',
      currency: 'Nakfa',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/eri.png'
    },
    {
      id: 66,
      name: 'Estonia',
      localname: '',
      alternatives: 'Estonian',
      iso2: 'EE',
      iso3: 'EST',
      ison: 233,
      e164: 372,
      fips: 'EN',
      tld: 'ee',
      area: 45226,
      phone_code: '372',
      continent: 'Europe',
      capital: 'Tallinn',
      capital_timezone: 'Europe/Tallinn',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/est.png'
    },
    {
      id: 67,
      name: 'Ethiopia',
      localname: '',
      alternatives: 'Ethiopian',
      iso2: 'ET',
      iso3: 'ETH',
      ison: 231,
      e164: 251,
      fips: 'ET',
      tld: 'et',
      area: 1127127,
      phone_code: '251',
      continent: 'Africa',
      capital: 'Addis Ababa',
      capital_timezone: 'Africa/Addis_Ababa',
      currency: 'Birr',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/eth.png'
    },
    {
      id: 68,
      name: 'Falkland Islands',
      localname: '',
      alternatives: 'Falkland Islanders',
      iso2: 'FK',
      iso3: 'FLK',
      ison: 238,
      e164: 500,
      fips: 'FK',
      tld: 'fk',
      area: 12173,
      phone_code: '500',
      continent: 'South America',
      capital: 'Stanley',
      capital_timezone: 'Atlantic/Stanley',
      currency: 'Pound',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/flk.png'
    },
    {
      id: 69,
      name: 'Faroe Islands',
      localname: '',
      alternatives: 'Faroese',
      iso2: 'FO',
      iso3: 'FRO',
      ison: 234,
      e164: 298,
      fips: 'FO',
      tld: 'fo',
      area: 1399,
      phone_code: '298',
      continent: 'Europe',
      capital: 'Torshavn',
      capital_timezone: 'Atlantic/Faroe',
      currency: 'Krone',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/fro.png'
    },
    {
      id: 70,
      name: 'Fiji',
      localname: '',
      alternatives: 'Fijian',
      iso2: 'FJ',
      iso3: 'FJI',
      ison: 242,
      e164: 679,
      fips: 'FJ',
      tld: 'fj',
      area: 18270,
      phone_code: '679',
      continent: 'Oceania',
      capital: 'Suva',
      capital_timezone: 'Pacific/Fiji',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/fji.png'
    },
    {
      id: 71,
      name: 'Finland',
      localname: '',
      alternatives: 'Finnish, Finns',
      iso2: 'FI',
      iso3: 'FIN',
      ison: 246,
      e164: 358,
      fips: 'FI',
      tld: 'fi',
      area: 337030,
      phone_code: '358',
      continent: 'Europe',
      capital: 'Helsinki',
      capital_timezone: 'Europe/Helsinki',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/fin.png'
    },
    {
      id: 72,
      name: 'France',
      localname: '',
      alternatives: 'French',
      iso2: 'FR',
      iso3: 'FRA',
      ison: 250,
      e164: 33,
      fips: 'FR',
      tld: 'fr',
      area: 547030,
      phone_code: '33',
      continent: 'Europe',
      capital: 'Paris',
      capital_timezone: 'Europe/Paris',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/fra.png'
    },
    {
      id: 73,
      name: 'French Polynesia',
      localname: '',
      alternatives: 'French Guianese',
      iso2: 'PF',
      iso3: 'PYF',
      ison: 258,
      e164: 689,
      fips: 'FP',
      tld: 'pf',
      area: 4167,
      phone_code: '689',
      continent: 'Oceania',
      capital: 'Papeete',
      capital_timezone: 'Pacific/Tahiti',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/pyf.png'
    },
    {
      id: 74,
      name: 'Gabon',
      localname: '',
      alternatives: 'Gabonese',
      iso2: 'GA',
      iso3: 'GAB',
      ison: 266,
      e164: 241,
      fips: 'GB',
      tld: 'ga',
      area: 267667,
      phone_code: '241',
      continent: 'Africa',
      capital: 'Libreville',
      capital_timezone: 'Africa/Libreville',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gab.png'
    },
    {
      id: 75,
      name: 'Gambia',
      localname: '',
      alternatives: 'Gambian',
      iso2: 'GM',
      iso3: 'GMB',
      ison: 270,
      e164: 220,
      fips: 'GA',
      tld: 'gm',
      area: 11300,
      phone_code: '220',
      continent: 'Africa',
      capital: 'Banjul',
      capital_timezone: 'Africa/Banjul',
      currency: 'Dalasi',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gmb.png'
    },
    {
      id: 76,
      name: 'Georgia',
      localname: '',
      alternatives: 'Georgian',
      iso2: 'GE',
      iso3: 'GEO',
      ison: 268,
      e164: 995,
      fips: 'GG',
      tld: 'ge',
      area: 69700,
      phone_code: '995',
      continent: 'Asia',
      capital: 'Tbilisi',
      capital_timezone: 'Asia/Tbilisi',
      currency: 'Lari',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/geo.png'
    },
    {
      id: 77,
      name: 'Germany',
      localname: '',
      alternatives: 'German, Deutsche',
      iso2: 'DE',
      iso3: 'DEU',
      ison: 276,
      e164: 49,
      fips: 'GM',
      tld: 'de',
      area: 357021,
      phone_code: '49',
      continent: 'Europe',
      capital: 'Berlin',
      capital_timezone: 'Europe/Berlin',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/deu.png'
    },
    {
      id: 78,
      name: 'Ghana',
      localname: '',
      alternatives: 'Ghanaian',
      iso2: 'GH',
      iso3: 'GHA',
      ison: 288,
      e164: 233,
      fips: 'GH',
      tld: 'gh',
      area: 239460,
      phone_code: '233',
      continent: 'Africa',
      capital: 'Accra',
      capital_timezone: 'Africa/Accra',
      currency: 'Cedi',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gha.png'
    },
    {
      id: 79,
      name: 'Gibraltar',
      localname: '',
      alternatives: 'Gibraltarians',
      iso2: 'GI',
      iso3: 'GIB',
      ison: 292,
      e164: 350,
      fips: 'GI',
      tld: 'gi',
      area: 7,
      phone_code: '350',
      continent: 'Europe',
      capital: 'Gibraltar',
      capital_timezone: 'Europe/Gibraltar',
      currency: 'Pound',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gib.png'
    },
    {
      id: 80,
      name: 'Greece',
      localname: '',
      alternatives: 'Greek, Hellenic',
      iso2: 'GR',
      iso3: 'GRC',
      ison: 300,
      e164: 30,
      fips: 'GR',
      tld: 'gr',
      area: 131940,
      phone_code: '30',
      continent: 'Europe',
      capital: 'Athens',
      capital_timezone: 'Europe/Athens',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/grc.png'
    },
    {
      id: 81,
      name: 'Greenland',
      localname: '',
      alternatives: 'Greenlandic',
      iso2: 'GL',
      iso3: 'GRL',
      ison: 304,
      e164: 299,
      fips: 'GL',
      tld: 'gl',
      area: 2166086,
      phone_code: '299',
      continent: 'North America',
      capital: 'Nuuk',
      capital_timezone: 'America/Godthab',
      currency: 'Krone',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/grl.png'
    },
    {
      id: 82,
      name: 'Grenada',
      localname: '',
      alternatives: 'Grenadian',
      iso2: 'GD',
      iso3: 'GRD',
      ison: 308,
      e164: 1,
      fips: 'GJ',
      tld: 'gd',
      area: 344,
      phone_code: '1-473',
      continent: 'North America',
      capital: "St. George's",
      capital_timezone: 'America/Grenada',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/grd.png'
    },
    {
      id: 83,
      name: 'Guam',
      localname: '',
      alternatives: 'Guamanian, Guambat',
      iso2: 'GU',
      iso3: 'GUM',
      ison: 316,
      e164: 1,
      fips: 'GQ',
      tld: 'gu',
      area: 549,
      phone_code: '1-671',
      continent: 'Oceania',
      capital: 'Hagatna',
      capital_timezone: 'Pacific/Guam',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gum.png'
    },
    {
      id: 84,
      name: 'Guatemala',
      localname: '',
      alternatives: 'Guatemalan',
      iso2: 'GT',
      iso3: 'GTM',
      ison: 320,
      e164: 502,
      fips: 'GT',
      tld: 'gt',
      area: 108890,
      phone_code: '502',
      continent: 'North America',
      capital: 'Guatemala City',
      capital_timezone: 'America/Guatemala',
      currency: 'Quetzal',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gtm.png'
    },
    {
      id: 85,
      name: 'Guinea',
      localname: '',
      alternatives: 'Guinean',
      iso2: 'GN',
      iso3: 'GIN',
      ison: 324,
      e164: 224,
      fips: 'GV',
      tld: 'gn',
      area: 245857,
      phone_code: '224',
      continent: 'Africa',
      capital: 'Conakry',
      capital_timezone: 'Africa/Conakry',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gin.png'
    },
    {
      id: 86,
      name: 'Guinea-Bissau',
      localname: '',
      alternatives: 'Bissau-Guinean',
      iso2: 'GW',
      iso3: 'GNB',
      ison: 624,
      e164: 245,
      fips: 'PU',
      tld: 'gw',
      area: 36120,
      phone_code: '245',
      continent: 'Africa',
      capital: 'Bissau',
      capital_timezone: 'Africa/Bissau',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gnb.png'
    },
    {
      id: 87,
      name: 'Guyana',
      localname: '',
      alternatives: 'Guyanese',
      iso2: 'GY',
      iso3: 'GUY',
      ison: 328,
      e164: 592,
      fips: 'GY',
      tld: 'gy',
      area: 214970,
      phone_code: '592',
      continent: 'South America',
      capital: 'Georgetown',
      capital_timezone: 'America/Guyana',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/guy.png'
    },
    {
      id: 88,
      name: 'Haiti',
      localname: '',
      alternatives: 'Haitian',
      iso2: 'HT',
      iso3: 'HTI',
      ison: 332,
      e164: 509,
      fips: 'HA',
      tld: 'ht',
      area: 27750,
      phone_code: '509',
      continent: 'North America',
      capital: 'Port-au-Prince',
      capital_timezone: 'America/Port-au-Prince',
      currency: 'Gourde',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/hti.png'
    },
    {
      id: 89,
      name: 'Honduras',
      localname: '',
      alternatives: 'Honduran, Catrachos',
      iso2: 'HN',
      iso3: 'HND',
      ison: 340,
      e164: 504,
      fips: 'HO',
      tld: 'hn',
      area: 112090,
      phone_code: '504',
      continent: 'North America',
      capital: 'Tegucigalpa',
      capital_timezone: 'America/Tegucigalpa',
      currency: 'Lempira',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/hnd.png'
    },
    {
      id: 90,
      name: 'Hong Kong',
      localname: '',
      alternatives: 'Hong Kongese',
      iso2: 'HK',
      iso3: 'HKG',
      ison: 344,
      e164: 852,
      fips: 'HK',
      tld: 'hk',
      area: 1092,
      phone_code: '852',
      continent: 'Asia',
      capital: 'Hong Kong',
      capital_timezone: 'Asia/Hong_Kong',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/hkg.png'
    },
    {
      id: 91,
      name: 'Hungary',
      localname: '',
      alternatives: 'Hungarian, Magyar',
      iso2: 'HU',
      iso3: 'HUN',
      ison: 348,
      e164: 36,
      fips: 'HU',
      tld: 'hu',
      area: 93030,
      phone_code: '36',
      continent: 'Europe',
      capital: 'Budapest',
      capital_timezone: 'Europe/Budapest',
      currency: 'Forint',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/hun.png'
    },
    {
      id: 92,
      name: 'Iceland',
      localname: '',
      alternatives: 'Icelandic',
      iso2: 'IS',
      iso3: 'ISL',
      ison: 352,
      e164: 354,
      fips: 'IC',
      tld: 'is',
      area: 103000,
      phone_code: '354',
      continent: 'Europe',
      capital: 'Reykjavik',
      capital_timezone: 'Atlantic/Reykjavik',
      currency: 'Krona',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/isl.png'
    },
    {
      id: 93,
      name: 'India',
      localname: '',
      alternatives: 'Indian',
      iso2: 'IN',
      iso3: 'IND',
      ison: 356,
      e164: 91,
      fips: 'IN',
      tld: 'in',
      area: 3287590,
      phone_code: '91',
      continent: 'Asia',
      capital: 'New Delhi',
      capital_timezone: 'Asia/Kolkata',
      currency: 'Rupee',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ind.png'
    },
    {
      id: 94,
      name: 'Indonesia',
      localname: '',
      alternatives: 'Indonesian',
      iso2: 'ID',
      iso3: 'IDN',
      ison: 360,
      e164: 62,
      fips: 'ID',
      tld: 'id',
      area: 1919440,
      phone_code: '62',
      continent: 'Asia',
      capital: 'Jakarta',
      capital_timezone: 'Asia/Jakarta',
      currency: 'Rupiah',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/idn.png'
    },
    {
      id: 95,
      name: 'Iran',
      localname: '',
      alternatives: 'Iranian, Persian',
      iso2: 'IR',
      iso3: 'IRN',
      ison: 364,
      e164: 98,
      fips: 'IR',
      tld: 'ir',
      area: 1648000,
      phone_code: '98',
      continent: 'Asia',
      capital: 'Tehran',
      capital_timezone: 'Asia/Tehran',
      currency: 'Rial',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/irn.png'
    },
    {
      id: 96,
      name: 'Iraq',
      localname: '',
      alternatives: 'Iraqi',
      iso2: 'IQ',
      iso3: 'IRQ',
      ison: 368,
      e164: 964,
      fips: 'IZ',
      tld: 'iq',
      area: 437072,
      phone_code: '964',
      continent: 'Asia',
      capital: 'Baghdad',
      capital_timezone: 'Asia/Baghdad',
      currency: 'Dinar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/irq.png'
    },
    {
      id: 97,
      name: 'Ireland',
      localname: '',
      alternatives: 'Irish',
      iso2: 'IE',
      iso3: 'IRL',
      ison: 372,
      e164: 353,
      fips: 'EI',
      tld: 'ie',
      area: 70280,
      phone_code: '353',
      continent: 'Europe',
      capital: 'Dublin',
      capital_timezone: 'Europe/Dublin',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/irl.png'
    },
    {
      id: 98,
      name: 'Isle of Man',
      localname: '',
      alternatives: 'Manx',
      iso2: 'IM',
      iso3: 'IMN',
      ison: 833,
      e164: 44,
      fips: 'IM',
      tld: 'im',
      area: 572,
      phone_code: '44-1624',
      continent: 'Europe',
      capital: 'Douglas, Isle of Man',
      capital_timezone: 'Europe/Isle_of_Man',
      currency: 'Pound',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/imn.png'
    },
    {
      id: 99,
      name: 'Italy',
      localname: '',
      alternatives: 'Italian',
      iso2: 'IT',
      iso3: 'ITA',
      ison: 380,
      e164: 39,
      fips: 'IT',
      tld: 'it',
      area: 301230,
      phone_code: '39',
      continent: 'Europe',
      capital: 'Rome',
      capital_timezone: 'Europe/Rome',
      currency: 'Euro',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ita.png'
    },
    {
      id: 100,
      name: 'Ivory Coast',
      localname: '',
      alternatives: 'Ivorian',
      iso2: 'CI',
      iso3: 'CIV',
      ison: 384,
      e164: 225,
      fips: 'IV',
      tld: 'ci',
      area: 322460,
      phone_code: '225',
      continent: 'Africa',
      capital: 'Yamoussoukro',
      capital_timezone: 'Africa/Abidjan',
      currency: 'Franc',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/civ.png'
    },
    {
      id: 101,
      name: 'Jamaica',
      localname: '',
      alternatives: 'Jamaican',
      iso2: 'JM',
      iso3: 'JAM',
      ison: 388,
      e164: 1,
      fips: 'JM',
      tld: 'jm',
      area: 10991,
      phone_code: '1-876',
      continent: 'North America',
      capital: 'Kingston',
      capital_timezone: 'America/Jamaica',
      currency: 'Dollar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/jam.png'
    },
    {
      id: 102,
      name: 'Japan',
      localname: '',
      alternatives: 'Japanese',
      iso2: 'JP',
      iso3: 'JPN',
      ison: 392,
      e164: 81,
      fips: 'JA',
      tld: 'jp',
      area: 377835,
      phone_code: '81',
      continent: 'Asia',
      capital: 'Tokyo',
      capital_timezone: 'Asia/Tokyo',
      currency: 'Yen',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/jpn.png'
    },
    {
      id: 103,
      name: 'Jersey',
      localname: '',
      alternatives: 'Channel Island',
      iso2: 'JE',
      iso3: 'JEY',
      ison: 832,
      e164: 44,
      fips: 'JE',
      tld: 'je',
      area: 116,
      phone_code: '44-1534',
      continent: 'Europe',
      capital: 'Saint Helier',
      capital_timezone: 'Europe/Jersey',
      currency: 'Pound',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/jey.png'
    },
    {
      id: 104,
      name: 'Jordan',
      localname: '',
      alternatives: 'Jordanian',
      iso2: 'JO',
      iso3: 'JOR',
      ison: 400,
      e164: 962,
      fips: 'JO',
      tld: 'jo',
      area: 92300,
      phone_code: '962',
      continent: 'Asia',
      capital: 'Amman',
      capital_timezone: 'Asia/Amman',
      currency: 'Dinar',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/jor.png'
    },
    {
      id: 105,
      name: 'Kazakhstan',
      localname: '',
      alternatives: 'Kazakhstani, Kazakh',
      iso2: 'KZ',
      iso3: 'KAZ',
      ison: 398,
      e164: 7,
      fips: 'KZ',
      tld: 'kz',
      area: 2717300,
      phone_code: '7',
      continent: 'Asia',
      capital: 'Astana',
      capital_timezone: 'Asia/Almaty',
      currency: 'Tenge',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/kaz.png'
    },
    {
      id: 106,
      name: 'Kenya',
      localname: '',
      alternatives: 'Kenyan',
      iso2: 'KE',
      iso3: 'KEN',
      ison: 404,
      e164: 254,
      fips: 'KE',
      tld: 'ke',
      area: 582650,
      phone_code: '254',
      continent: 'Africa',
      capital: 'Nairobi',
      capital_timezone: 'Africa/Nairobi',
      currency: 'Shilling',
      created: '2016-01-11 16:17:32',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ken.png'
    },
    {
      id: 107,
      name: 'Kiribati',
      localname: '',
      alternatives: 'I-Kiribati',
      iso2: 'KI',
      iso3: 'KIR',
      ison: 296,
      e164: 686,
      fips: 'KR',
      tld: 'ki',
      area: 811,
      phone_code: '686',
      continent: 'Oceania',
      capital: 'Tarawa',
      capital_timezone: 'Pacific/Tarawa',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/kir.png'
    },
    {
      id: 108,
      name: 'Kuwait',
      localname: '',
      alternatives: 'Kuwaiti',
      iso2: 'KW',
      iso3: 'KWT',
      ison: 414,
      e164: 965,
      fips: 'KU',
      tld: 'kw',
      area: 17820,
      phone_code: '965',
      continent: 'Asia',
      capital: 'Kuwait City',
      capital_timezone: 'Asia/Kuwait',
      currency: 'Dinar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/kwt.png'
    },
    {
      id: 109,
      name: 'Kyrgyzstan',
      localname: '',
      alternatives: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
      iso2: 'KG',
      iso3: 'KGZ',
      ison: 417,
      e164: 996,
      fips: 'KG',
      tld: 'kg',
      area: 198500,
      phone_code: '996',
      continent: 'Asia',
      capital: 'Bishkek',
      capital_timezone: 'Asia/Bishkek',
      currency: 'Som',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/kgz.png'
    },
    {
      id: 110,
      name: 'Laos',
      localname: '',
      alternatives: 'Lao, Laotian',
      iso2: 'LA',
      iso3: 'LAO',
      ison: 418,
      e164: 856,
      fips: 'LA',
      tld: 'la',
      area: 236800,
      phone_code: '856',
      continent: 'Asia',
      capital: 'Vientiane',
      capital_timezone: 'Asia/Vientiane',
      currency: 'Kip',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lao.png'
    },
    {
      id: 111,
      name: 'Latvia',
      localname: '',
      alternatives: 'Latvian, Lettish',
      iso2: 'LV',
      iso3: 'LVA',
      ison: 428,
      e164: 371,
      fips: 'LG',
      tld: 'lv',
      area: 64589,
      phone_code: '371',
      continent: 'Europe',
      capital: 'Riga',
      capital_timezone: 'Europe/Riga',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lva.png'
    },
    {
      id: 112,
      name: 'Lebanon',
      localname: '',
      alternatives: 'Lebanese',
      iso2: 'LB',
      iso3: 'LBN',
      ison: 422,
      e164: 961,
      fips: 'LE',
      tld: 'lb',
      area: 10400,
      phone_code: '961',
      continent: 'Asia',
      capital: 'Beirut',
      capital_timezone: 'Asia/Beirut',
      currency: 'Pound',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lbn.png'
    },
    {
      id: 113,
      name: 'Lesotho',
      localname: '',
      alternatives: 'Basotho',
      iso2: 'LS',
      iso3: 'LSO',
      ison: 426,
      e164: 266,
      fips: 'LT',
      tld: 'ls',
      area: 30355,
      phone_code: '266',
      continent: 'Africa',
      capital: 'Maseru',
      capital_timezone: 'Africa/Maseru',
      currency: 'Loti',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lso.png'
    },
    {
      id: 114,
      name: 'Liberia',
      localname: '',
      alternatives: 'Liberian',
      iso2: 'LR',
      iso3: 'LBR',
      ison: 430,
      e164: 231,
      fips: 'LI',
      tld: 'lr',
      area: 111370,
      phone_code: '231',
      continent: 'Africa',
      capital: 'Monrovia',
      capital_timezone: 'Africa/Monrovia',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lbr.png'
    },
    {
      id: 115,
      name: 'Libya',
      localname: '',
      alternatives: 'Libyan',
      iso2: 'LY',
      iso3: 'LBY',
      ison: 434,
      e164: 218,
      fips: 'LY',
      tld: 'ly',
      area: 1759540,
      phone_code: '218',
      continent: 'Africa',
      capital: 'Tripolis',
      capital_timezone: 'Africa/Tripoli',
      currency: 'Dinar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lby.png'
    },
    {
      id: 116,
      name: 'Liechtenstein',
      localname: '',
      alternatives: 'Liechtensteiner',
      iso2: 'LI',
      iso3: 'LIE',
      ison: 438,
      e164: 423,
      fips: 'LS',
      tld: 'li',
      area: 160,
      phone_code: '423',
      continent: 'Europe',
      capital: 'Vaduz',
      capital_timezone: 'Europe/Vaduz',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lie.png'
    },
    {
      id: 117,
      name: 'Lithuania',
      localname: '',
      alternatives: 'Lithuanian',
      iso2: 'LT',
      iso3: 'LTU',
      ison: 440,
      e164: 370,
      fips: 'LH',
      tld: 'lt',
      area: 65200,
      phone_code: '370',
      continent: 'Europe',
      capital: 'Vilnius',
      capital_timezone: 'Europe/Vilnius',
      currency: 'Litas',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ltu.png'
    },
    {
      id: 118,
      name: 'Luxembourg',
      localname: '',
      alternatives: 'Luxembourg, Luxembourgish',
      iso2: 'LU',
      iso3: 'LUX',
      ison: 442,
      e164: 352,
      fips: 'LU',
      tld: 'lu',
      area: 2586,
      phone_code: '352',
      continent: 'Europe',
      capital: 'Luxembourg',
      capital_timezone: 'Europe/Luxembourg',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lux.png'
    },
    {
      id: 119,
      name: 'Macao',
      localname: '',
      alternatives: 'Macanese, Chinese',
      iso2: 'MO',
      iso3: 'MAC',
      ison: 446,
      e164: 853,
      fips: 'MC',
      tld: 'mo',
      area: 254,
      phone_code: '853',
      continent: 'Asia',
      capital: 'Macao',
      capital_timezone: 'Asia/Macau',
      currency: 'Pataca',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mac.png'
    },
    {
      id: 120,
      name: 'Macedonia',
      localname: '',
      alternatives: 'Macedonian',
      iso2: 'MK',
      iso3: 'MKD',
      ison: 807,
      e164: 389,
      fips: 'MK',
      tld: 'mk',
      area: 25333,
      phone_code: '389',
      continent: 'Europe',
      capital: 'Skopje',
      capital_timezone: 'Europe/Skopje',
      currency: 'Denar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mkd.png'
    },
    {
      id: 121,
      name: 'Madagascar',
      localname: '',
      alternatives: 'Malagasy',
      iso2: 'MG',
      iso3: 'MDG',
      ison: 450,
      e164: 261,
      fips: 'MA',
      tld: 'mg',
      area: 587040,
      phone_code: '261',
      continent: 'Africa',
      capital: 'Antananarivo',
      capital_timezone: 'Indian/Antananarivo',
      currency: 'Ariary',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mdg.png'
    },
    {
      id: 122,
      name: 'Malawi',
      localname: '',
      alternatives: 'Malawian',
      iso2: 'MW',
      iso3: 'MWI',
      ison: 454,
      e164: 265,
      fips: 'MI',
      tld: 'mw',
      area: 118480,
      phone_code: '265',
      continent: 'Africa',
      capital: 'Lilongwe',
      capital_timezone: 'Africa/Blantyre',
      currency: 'Kwacha',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mwi.png'
    },
    {
      id: 123,
      name: 'Malaysia',
      localname: '',
      alternatives: 'Malaysian',
      iso2: 'MY',
      iso3: 'MYS',
      ison: 458,
      e164: 60,
      fips: 'MY',
      tld: 'my',
      area: 329750,
      phone_code: '60',
      continent: 'Asia',
      capital: 'Kuala Lumpur',
      capital_timezone: 'Asia/Kuala_Lumpur',
      currency: 'Ringgit',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mys.png'
    },
    {
      id: 124,
      name: 'Maldives',
      localname: '',
      alternatives: 'Maldivian',
      iso2: 'MV',
      iso3: 'MDV',
      ison: 462,
      e164: 960,
      fips: 'MV',
      tld: 'mv',
      area: 300,
      phone_code: '960',
      continent: 'Asia',
      capital: 'Male',
      capital_timezone: 'Indian/Maldives',
      currency: 'Rufiyaa',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mdv.png'
    },
    {
      id: 125,
      name: 'Mali',
      localname: '',
      alternatives: 'Malian, Malinese',
      iso2: 'ML',
      iso3: 'MLI',
      ison: 466,
      e164: 223,
      fips: 'ML',
      tld: 'ml',
      area: 1240000,
      phone_code: '223',
      continent: 'Africa',
      capital: 'Bamako',
      capital_timezone: 'Africa/Bamako',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mli.png'
    },
    {
      id: 126,
      name: 'Malta',
      localname: '',
      alternatives: 'Maltese',
      iso2: 'MT',
      iso3: 'MLT',
      ison: 470,
      e164: 356,
      fips: 'MT',
      tld: 'mt',
      area: 316,
      phone_code: '356',
      continent: 'Europe',
      capital: 'Valletta',
      capital_timezone: 'Europe/Malta',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mlt.png'
    },
    {
      id: 127,
      name: 'Marshall Islands',
      localname: '',
      alternatives: 'Marshallese',
      iso2: 'MH',
      iso3: 'MHL',
      ison: 584,
      e164: 692,
      fips: 'RM',
      tld: 'mh',
      area: 181,
      phone_code: '692',
      continent: 'Oceania',
      capital: 'Majuro',
      capital_timezone: 'Pacific/Majuro',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mhl.png'
    },
    {
      id: 128,
      name: 'Mauritania',
      localname: '',
      alternatives: 'Mauritanian',
      iso2: 'MR',
      iso3: 'MRT',
      ison: 478,
      e164: 222,
      fips: 'MR',
      tld: 'mr',
      area: 1030700,
      phone_code: '222',
      continent: 'Africa',
      capital: 'Nouakchott',
      capital_timezone: 'Africa/Nouakchott',
      currency: 'Ouguiya',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mrt.png'
    },
    {
      id: 129,
      name: 'Mauritius',
      localname: '',
      alternatives: 'Mauritian',
      iso2: 'MU',
      iso3: 'MUS',
      ison: 480,
      e164: 230,
      fips: 'MP',
      tld: 'mu',
      area: 2040,
      phone_code: '230',
      continent: 'Africa',
      capital: 'Port Louis',
      capital_timezone: 'Indian/Mauritius',
      currency: 'Rupee',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mus.png'
    },
    {
      id: 130,
      name: 'Mayotte',
      localname: '',
      alternatives: 'Mahoran',
      iso2: 'YT',
      iso3: 'MYT',
      ison: 175,
      e164: 262,
      fips: 'MF',
      tld: 'yt',
      area: 374,
      phone_code: '262',
      continent: 'Africa',
      capital: 'Mamoudzou',
      capital_timezone: 'Indian/Mayotte',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/myt.png'
    },
    {
      id: 131,
      name: 'Mexico',
      localname: '',
      alternatives: 'Mexican',
      iso2: 'MX',
      iso3: 'MEX',
      ison: 484,
      e164: 52,
      fips: 'MX',
      tld: 'mx',
      area: 1972550,
      phone_code: '52',
      continent: 'North America',
      capital: 'Mexico City',
      capital_timezone: 'America/Mexico_City',
      currency: 'Peso',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mex.png'
    },
    {
      id: 132,
      name: 'Micronesia',
      localname: '',
      alternatives: 'Micronesian',
      iso2: 'FM',
      iso3: 'FSM',
      ison: 583,
      e164: 691,
      fips: 'FM',
      tld: 'fm',
      area: 702,
      phone_code: '691',
      continent: 'Oceania',
      capital: 'Palikir',
      capital_timezone: 'Pacific/Pohnpei',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/fsm.png'
    },
    {
      id: 133,
      name: 'Moldova',
      localname: '',
      alternatives: 'Moldovan',
      iso2: 'MD',
      iso3: 'MDA',
      ison: 498,
      e164: 373,
      fips: 'MD',
      tld: 'md',
      area: 33843,
      phone_code: '373',
      continent: 'Europe',
      capital: 'Chisinau',
      capital_timezone: 'Europe/Chisinau',
      currency: 'Leu',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mda.png'
    },
    {
      id: 134,
      name: 'Monaco',
      localname: '',
      alternatives: 'Monégasque, Monacan',
      iso2: 'MC',
      iso3: 'MCO',
      ison: 492,
      e164: 377,
      fips: 'MN',
      tld: 'mc',
      area: 2,
      phone_code: '377',
      continent: 'Europe',
      capital: 'Monaco',
      capital_timezone: 'Europe/Monaco',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mco.png'
    },
    {
      id: 135,
      name: 'Mongolia',
      localname: '',
      alternatives: 'Mongolian, Mongols',
      iso2: 'MN',
      iso3: 'MNG',
      ison: 496,
      e164: 976,
      fips: 'MG',
      tld: 'mn',
      area: 1565000,
      phone_code: '976',
      continent: 'Asia',
      capital: 'Ulan Bator',
      capital_timezone: 'Asia/Ulaanbaatar',
      currency: 'Tugrik',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mng.png'
    },
    {
      id: 136,
      name: 'Montenegro',
      localname: '',
      alternatives: 'Montenegrin',
      iso2: 'ME',
      iso3: 'MNE',
      ison: 499,
      e164: 382,
      fips: 'MJ',
      tld: 'me',
      area: 14026,
      phone_code: '382',
      continent: 'Europe',
      capital: 'Podgorica',
      capital_timezone: 'Europe/Podgorica',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mne.png'
    },
    {
      id: 137,
      name: 'Montserrat',
      localname: '',
      alternatives: 'Montserratian',
      iso2: 'MS',
      iso3: 'MSR',
      ison: 500,
      e164: 1,
      fips: 'MH',
      tld: 'ms',
      area: 102,
      phone_code: '1-664',
      continent: 'North America',
      capital: 'Plymouth',
      capital_timezone: 'America/Montserrat',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/msr.png'
    },
    {
      id: 138,
      name: 'Morocco',
      localname: '',
      alternatives: 'Moroccan',
      iso2: 'MA',
      iso3: 'MAR',
      ison: 504,
      e164: 212,
      fips: 'MO',
      tld: 'ma',
      area: 446550,
      phone_code: '212',
      continent: 'Africa',
      capital: 'Rabat',
      capital_timezone: 'Africa/Casablanca',
      currency: 'Dirham',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mar.png'
    },
    {
      id: 139,
      name: 'Mozambique',
      localname: '',
      alternatives: 'Mozambican',
      iso2: 'MZ',
      iso3: 'MOZ',
      ison: 508,
      e164: 258,
      fips: 'MZ',
      tld: 'mz',
      area: 801590,
      phone_code: '258',
      continent: 'Africa',
      capital: 'Maputo',
      capital_timezone: 'Africa/Maputo',
      currency: 'Metical',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/moz.png'
    },
    {
      id: 140,
      name: 'Myanmar',
      localname: '',
      alternatives: 'Burmese',
      iso2: 'MM',
      iso3: 'MMR',
      ison: 104,
      e164: 95,
      fips: 'BM',
      tld: 'mm',
      area: 678500,
      phone_code: '95',
      continent: 'Asia',
      capital: 'Nay Pyi Taw',
      capital_timezone: 'Asia/Rangoon',
      currency: 'Kyat',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mmr.png'
    },
    {
      id: 141,
      name: 'Namibia',
      localname: '',
      alternatives: 'Namibian',
      iso2: 'NA',
      iso3: 'NAM',
      ison: 516,
      e164: 264,
      fips: 'WA',
      tld: 'na',
      area: 825418,
      phone_code: '264',
      continent: 'Africa',
      capital: 'Windhoek',
      capital_timezone: 'Africa/Windhoek',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/nam.png'
    },
    {
      id: 142,
      name: 'Nauru',
      localname: '',
      alternatives: 'Nauruan',
      iso2: 'NR',
      iso3: 'NRU',
      ison: 520,
      e164: 674,
      fips: 'NR',
      tld: 'nr',
      area: 21,
      phone_code: '674',
      continent: 'Oceania',
      capital: 'Yaren',
      capital_timezone: 'Pacific/Nauru',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/nru.png'
    },
    {
      id: 143,
      name: 'Nepal',
      localname: '',
      alternatives: 'Nepali, Nepalese',
      iso2: 'NP',
      iso3: 'NPL',
      ison: 524,
      e164: 977,
      fips: 'NP',
      tld: 'np',
      area: 140800,
      phone_code: '977',
      continent: 'Asia',
      capital: 'Kathmandu',
      capital_timezone: 'Asia/Kathmandu',
      currency: 'Rupee',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/npl.png'
    },
    {
      id: 144,
      name: 'Netherlands',
      localname: '',
      alternatives: 'Dutch, Netherlandic',
      iso2: 'NL',
      iso3: 'NLD',
      ison: 528,
      e164: 31,
      fips: 'NL',
      tld: 'nl',
      area: 41526,
      phone_code: '31',
      continent: 'Europe',
      capital: 'Amsterdam',
      capital_timezone: 'Europe/Amsterdam',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/nld.png'
    },
    {
      id: 145,
      name: 'Netherlands Antilles',
      localname: '',
      alternatives: '',
      iso2: 'AN',
      iso3: 'ANT',
      ison: 530,
      e164: 599,
      fips: 'NT',
      tld: 'an',
      area: 960,
      phone_code: '599',
      continent: 'North America',
      capital: 'Willemstad',
      capital_timezone: '',
      currency: 'Guilder',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ant.png'
    },
    {
      id: 146,
      name: 'New Caledonia',
      localname: '',
      alternatives: 'New Caledonian',
      iso2: 'NC',
      iso3: 'NCL',
      ison: 540,
      e164: 687,
      fips: 'NC',
      tld: 'nc',
      area: 19060,
      phone_code: '687',
      continent: 'Oceania',
      capital: 'Noumea',
      capital_timezone: 'Pacific/Noumea',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ncl.png'
    },
    {
      id: 147,
      name: 'New Zealand',
      localname: '',
      alternatives: 'New Zealander, Kiwi',
      iso2: 'NZ',
      iso3: 'NZL',
      ison: 554,
      e164: 64,
      fips: 'NZ',
      tld: 'nz',
      area: 268680,
      phone_code: '64',
      continent: 'Oceania',
      capital: 'Wellington',
      capital_timezone: 'Pacific/Auckland',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/nzl.png'
    },
    {
      id: 148,
      name: 'Nicaragua',
      localname: '',
      alternatives: 'Nicaraguan',
      iso2: 'NI',
      iso3: 'NIC',
      ison: 558,
      e164: 505,
      fips: 'NU',
      tld: 'ni',
      area: 129494,
      phone_code: '505',
      continent: 'North America',
      capital: 'Managua',
      capital_timezone: 'America/Managua',
      currency: 'Cordoba',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/nic.png'
    },
    {
      id: 149,
      name: 'Niger',
      localname: '',
      alternatives: 'Nigerien',
      iso2: 'NE',
      iso3: 'NER',
      ison: 562,
      e164: 227,
      fips: 'NG',
      tld: 'ne',
      area: 1267000,
      phone_code: '227',
      continent: 'Africa',
      capital: 'Niamey',
      capital_timezone: 'Africa/Niamey',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ner.png'
    },
    {
      id: 150,
      name: 'Nigeria',
      localname: '',
      alternatives: 'Nigerian',
      iso2: 'NG',
      iso3: 'NGA',
      ison: 566,
      e164: 234,
      fips: 'NI',
      tld: 'ng',
      area: 923768,
      phone_code: '234',
      continent: 'Africa',
      capital: 'Abuja',
      capital_timezone: 'Africa/Lagos',
      currency: 'Naira',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/nga.png'
    },
    {
      id: 151,
      name: 'Niue',
      localname: '',
      alternatives: 'Niuean',
      iso2: 'NU',
      iso3: 'NIU',
      ison: 570,
      e164: 683,
      fips: 'NE',
      tld: 'nu',
      area: 260,
      phone_code: '683',
      continent: 'Oceania',
      capital: 'Alofi',
      capital_timezone: 'Pacific/Niue',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/niu.png'
    },
    {
      id: 152,
      name: 'North Korea',
      localname: '',
      alternatives: 'North Korean',
      iso2: 'KP',
      iso3: 'PRK',
      ison: 408,
      e164: 850,
      fips: 'KN',
      tld: 'kp',
      area: 120540,
      phone_code: '850',
      continent: 'Asia',
      capital: 'Pyongyang',
      capital_timezone: 'Asia/Pyongyang',
      currency: 'Won',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/prk.png'
    },
    {
      id: 153,
      name: 'Northern Mariana Islands',
      localname: '',
      alternatives: 'Northern Marianan',
      iso2: 'MP',
      iso3: 'MNP',
      ison: 580,
      e164: 1,
      fips: 'CQ',
      tld: 'mp',
      area: 477,
      phone_code: '1-670',
      continent: 'Oceania',
      capital: 'Saipan',
      capital_timezone: 'Pacific/Saipan',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/mnp.png'
    },
    {
      id: 154,
      name: 'Norway',
      localname: '',
      alternatives: 'Norwegian',
      iso2: 'NO',
      iso3: 'NOR',
      ison: 578,
      e164: 47,
      fips: 'NO',
      tld: 'no',
      area: 324220,
      phone_code: '47',
      continent: 'Europe',
      capital: 'Oslo',
      capital_timezone: 'Europe/Oslo',
      currency: 'Krone',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/nor.png'
    },
    {
      id: 155,
      name: 'Oman',
      localname: '',
      alternatives: 'Omani',
      iso2: 'OM',
      iso3: 'OMN',
      ison: 512,
      e164: 968,
      fips: 'MU',
      tld: 'om',
      area: 212460,
      phone_code: '968',
      continent: 'Asia',
      capital: 'Muscat',
      capital_timezone: 'Asia/Muscat',
      currency: 'Rial',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/omn.png'
    },
    {
      id: 156,
      name: 'Pakistan',
      localname: '',
      alternatives: 'Pakistani',
      iso2: 'PK',
      iso3: 'PAK',
      ison: 586,
      e164: 92,
      fips: 'PK',
      tld: 'pk',
      area: 803940,
      phone_code: '92',
      continent: 'Asia',
      capital: 'Islamabad',
      capital_timezone: 'Asia/Karachi',
      currency: 'Rupee',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/pak.png'
    },
    {
      id: 157,
      name: 'Palau',
      localname: '',
      alternatives: 'Palauan',
      iso2: 'PW',
      iso3: 'PLW',
      ison: 585,
      e164: 680,
      fips: 'PS',
      tld: 'pw',
      area: 458,
      phone_code: '680',
      continent: 'Oceania',
      capital: 'Melekeok',
      capital_timezone: 'Pacific/Palau',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/plw.png'
    },
    {
      id: 158,
      name: 'Palestine',
      localname: '',
      alternatives: 'Palestenian, Israeli, West Bank, Gaza',
      iso2: 'PS',
      iso3: 'PAL',
      ison: null,
      e164: 970,
      fips: '',
      tld: '',
      area: null,
      flag: 'https://library.micetribe.com/flags/pal.png',
      phone_code: '',
      continent: '',
      capital: '',
      capital_timezone: '',
      currency: '',
      created: '',
      updated: '2022-05-31 08:24:09'
    },
    {
      id: 159,
      name: 'Panama',
      localname: '',
      alternatives: 'Panamanian, Panas',
      iso2: 'PA',
      iso3: 'PAN',
      ison: 591,
      e164: 507,
      fips: 'PM',
      tld: 'pa',
      area: 78200,
      phone_code: '507',
      continent: 'North America',
      capital: 'Panama City',
      capital_timezone: 'America/Panama',
      currency: 'Balboa',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/pan.png'
    },
    {
      id: 160,
      name: 'Papua New Guinea',
      localname: '',
      alternatives: 'Papua New Guinean, Papuan',
      iso2: 'PG',
      iso3: 'PNG',
      ison: 598,
      e164: 675,
      fips: 'PP',
      tld: 'pg',
      area: 462840,
      phone_code: '675',
      continent: 'Oceania',
      capital: 'Port Moresby',
      capital_timezone: 'Pacific/Port_Moresby',
      currency: 'Kina',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/png.png'
    },
    {
      id: 161,
      name: 'Paraguay',
      localname: '',
      alternatives: 'Paraguayan',
      iso2: 'PY',
      iso3: 'PRY',
      ison: 600,
      e164: 595,
      fips: 'PA',
      tld: 'py',
      area: 406750,
      phone_code: '595',
      continent: 'South America',
      capital: 'Asuncion',
      capital_timezone: 'America/Asuncion',
      currency: 'Guarani',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/pry.png'
    },
    {
      id: 162,
      name: 'Peru',
      localname: '',
      alternatives: 'Peruvian',
      iso2: 'PE',
      iso3: 'PER',
      ison: 604,
      e164: 51,
      fips: 'PE',
      tld: 'pe',
      area: 1285220,
      phone_code: '51',
      continent: 'South America',
      capital: 'Lima',
      capital_timezone: 'America/Lima',
      currency: 'Sol',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/per.png'
    },
    {
      id: 163,
      name: 'Philippines',
      localname: '',
      alternatives: 'Filipino',
      iso2: 'PH',
      iso3: 'PHL',
      ison: 608,
      e164: 63,
      fips: 'RP',
      tld: 'ph',
      area: 300000,
      phone_code: '63',
      continent: 'Asia',
      capital: 'Manila',
      capital_timezone: 'Asia/Manila',
      currency: 'Peso',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/phl.png'
    },
    {
      id: 164,
      name: 'Pitcairn',
      localname: '',
      alternatives: 'Pitcairn Islanders',
      iso2: 'PN',
      iso3: 'PCN',
      ison: 612,
      e164: 870,
      fips: 'PC',
      tld: 'pn',
      area: 47,
      phone_code: '870',
      continent: 'Oceania',
      capital: 'Adamstown',
      capital_timezone: 'Pacific/Pitcairn',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/pcn.png'
    },
    {
      id: 165,
      name: 'Poland',
      localname: '',
      alternatives: 'Polish',
      iso2: 'PL',
      iso3: 'POL',
      ison: 616,
      e164: 48,
      fips: 'PL',
      tld: 'pl',
      area: 312685,
      phone_code: '48',
      continent: 'Europe',
      capital: 'Warsaw',
      capital_timezone: 'Europe/Warsaw',
      currency: 'Zloty',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/pol.png'
    },
    {
      id: 166,
      name: 'Portugal',
      localname: '',
      alternatives: 'Portuguese',
      iso2: 'PT',
      iso3: 'PRT',
      ison: 620,
      e164: 351,
      fips: 'PO',
      tld: 'pt',
      area: 92391,
      phone_code: '351',
      continent: 'Europe',
      capital: 'Lisbon',
      capital_timezone: 'Europe/Lisbon',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/prt.png'
    },
    {
      id: 167,
      name: 'Puerto Rico',
      localname: '',
      alternatives: 'Puerto Rican',
      iso2: 'PR',
      iso3: 'PRI',
      ison: 630,
      e164: 1,
      fips: 'RQ',
      tld: 'pr',
      area: 9104,
      phone_code: '1-787 and ',
      continent: 'North America',
      capital: 'San Juan',
      capital_timezone: 'America/Puerto_Rico',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/pri.png'
    },
    {
      id: 168,
      name: 'Qatar',
      localname: '',
      alternatives: 'Qatari',
      iso2: 'QA',
      iso3: 'QAT',
      ison: 634,
      e164: 974,
      fips: 'QA',
      tld: 'qa',
      area: 11437,
      phone_code: '974',
      continent: 'Asia',
      capital: 'Doha',
      capital_timezone: 'Asia/Qatar',
      currency: 'Rial',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/qat.png'
    },
    {
      id: 169,
      name: 'Republic of the Congo',
      localname: '',
      alternatives: 'Congolese',
      iso2: 'CG',
      iso3: 'COG',
      ison: 178,
      e164: 242,
      fips: 'CF',
      tld: 'cg',
      area: 342000,
      phone_code: '242',
      continent: 'Africa',
      capital: 'Brazzaville',
      capital_timezone: 'Africa/Brazzaville',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/cog.png'
    },
    {
      id: 170,
      name: 'Romania',
      localname: '',
      alternatives: 'Romanian',
      iso2: 'RO',
      iso3: 'ROU',
      ison: 642,
      e164: 40,
      fips: 'RO',
      tld: 'ro',
      area: 237500,
      phone_code: '40',
      continent: 'Europe',
      capital: 'Bucharest',
      capital_timezone: 'Europe/Bucharest',
      currency: 'Leu',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/rou.png'
    },
    {
      id: 171,
      name: 'Russia',
      localname: '',
      alternatives: 'Russian',
      iso2: 'RU',
      iso3: 'RUS',
      ison: 643,
      e164: 7,
      fips: 'RS',
      tld: 'ru',
      area: 17100000,
      phone_code: '7',
      continent: 'Europe',
      capital: 'Moscow',
      capital_timezone: 'Europe/Moscow',
      currency: 'Ruble',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/rus.png'
    },
    {
      id: 172,
      name: 'Rwanda',
      localname: '',
      alternatives: 'Rwandan',
      iso2: 'RW',
      iso3: 'RWA',
      ison: 646,
      e164: 250,
      fips: 'RW',
      tld: 'rw',
      area: 26338,
      phone_code: '250',
      continent: 'Africa',
      capital: 'Kigali',
      capital_timezone: 'Africa/Kigali',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/rwa.png'
    },
    {
      id: 173,
      name: 'Saint Barthelemy',
      localname: '',
      alternatives: '        Barthelemois',
      iso2: 'BL',
      iso3: 'BLM',
      ison: 652,
      e164: 590,
      fips: 'TB',
      tld: 'gp',
      area: 21,
      phone_code: '590',
      continent: 'North America',
      capital: 'Gustavia',
      capital_timezone: 'America/St_Barthelemy',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/blm.png'
    },
    {
      id: 174,
      name: 'Saint Helena',
      localname: '',
      alternatives: 'Saint Helenian',
      iso2: 'SH',
      iso3: 'SHN',
      ison: 654,
      e164: 290,
      fips: 'SH',
      tld: 'sh',
      area: 410,
      phone_code: '290',
      continent: 'Africa',
      capital: 'Jamestown',
      capital_timezone: 'Atlantic/St_Helena',
      currency: 'Pound',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/shn.png'
    },
    {
      id: 175,
      name: 'Saint Kitts and Nevis',
      localname: '',
      alternatives: 'Kittitian, Nevisian',
      iso2: 'KN',
      iso3: 'KNA',
      ison: 659,
      e164: 1,
      fips: 'SC',
      tld: 'kn',
      area: 261,
      phone_code: '1-869',
      continent: 'North America',
      capital: 'Basseterre',
      capital_timezone: 'America/St_Kitts',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/kna.png'
    },
    {
      id: 176,
      name: 'Saint Lucia',
      localname: '',
      alternatives: 'Saint Lucian',
      iso2: 'LC',
      iso3: 'LCA',
      ison: 662,
      e164: 1,
      fips: 'ST',
      tld: 'lc',
      area: 616,
      phone_code: '1-758',
      continent: 'North America',
      capital: 'Castries',
      capital_timezone: 'America/St_Lucia',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lca.png'
    },
    {
      id: 177,
      name: 'Saint Martin',
      localname: '',
      alternatives: 'Saint-Martinoise',
      iso2: 'MF',
      iso3: 'MAF',
      ison: 663,
      e164: 1,
      fips: 'RN',
      tld: 'gp',
      area: 53,
      phone_code: '590',
      continent: 'North America',
      capital: 'Marigot',
      capital_timezone: 'America/Marigot',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/maf.png'
    },
    {
      id: 178,
      name: 'Saint Pierre and Miquelon',
      localname: '',
      alternatives: 'Saint-Pierrais, Miquelonnais',
      iso2: 'PM',
      iso3: 'SPM',
      ison: 666,
      e164: 508,
      fips: 'SB',
      tld: 'pm',
      area: 242,
      phone_code: '508',
      continent: 'North America',
      capital: 'Saint-Pierre',
      capital_timezone: 'America/Miquelon',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/spm.png'
    },
    {
      id: 179,
      name: 'Saint Vincent and the Grenadines',
      localname: '',
      alternatives: 'Saint Vincentian, Vincentian',
      iso2: 'VC',
      iso3: 'VCT',
      ison: 670,
      e164: 1,
      fips: 'VC',
      tld: 'vc',
      area: 389,
      phone_code: '1-784',
      continent: 'North America',
      capital: 'Kingstown',
      capital_timezone: 'America/St_Vincent',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/vct.png'
    },
    {
      id: 180,
      name: 'Samoa',
      localname: '',
      alternatives: 'Samoan',
      iso2: 'WS',
      iso3: 'WSM',
      ison: 882,
      e164: 685,
      fips: 'WS',
      tld: 'ws',
      area: 2944,
      phone_code: '685',
      continent: 'Oceania',
      capital: 'Apia',
      capital_timezone: 'Pacific/Apia',
      currency: 'Tala',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/wsm.png'
    },
    {
      id: 181,
      name: 'San Marino',
      localname: '',
      alternatives: 'Sammarinese',
      iso2: 'SM',
      iso3: 'SMR',
      ison: 674,
      e164: 378,
      fips: 'SM',
      tld: 'sm',
      area: 61,
      phone_code: '378',
      continent: 'Europe',
      capital: 'San Marino',
      capital_timezone: 'Europe/San_Marino',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/smr.png'
    },
    {
      id: 182,
      name: 'Sao Tome and Principe',
      localname: '',
      alternatives: 'Sao Tomean',
      iso2: 'ST',
      iso3: 'STP',
      ison: 678,
      e164: 239,
      fips: 'TP',
      tld: 'st',
      area: 1001,
      phone_code: '239',
      continent: 'Africa',
      capital: 'Sao Tome',
      capital_timezone: 'Africa/Sao_Tome',
      currency: 'Dobra',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/stp.png'
    },
    {
      id: 183,
      name: 'Saudi Arabia',
      localname: '',
      alternatives: 'Saudi, Saudi Arabian',
      iso2: 'SA',
      iso3: 'SAU',
      ison: 682,
      e164: 966,
      fips: 'SA',
      tld: 'sa',
      area: 1960582,
      phone_code: '966',
      continent: 'Asia',
      capital: 'Riyadh',
      capital_timezone: 'Asia/Riyadh',
      currency: 'Rial',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sau.png'
    },
    {
      id: 184,
      name: 'Senegal',
      localname: '',
      alternatives: 'Senegalese',
      iso2: 'SN',
      iso3: 'SEN',
      ison: 686,
      e164: 221,
      fips: 'SG',
      tld: 'sn',
      area: 196190,
      phone_code: '221',
      continent: 'Africa',
      capital: 'Dakar',
      capital_timezone: 'Africa/Dakar',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sen.png'
    },
    {
      id: 185,
      name: 'Serbia',
      localname: '',
      alternatives: 'Serbian',
      iso2: 'RS',
      iso3: 'SRB',
      ison: 688,
      e164: 381,
      fips: 'RI',
      tld: 'rs',
      area: 88361,
      phone_code: '381',
      continent: 'Europe',
      capital: 'Belgrade',
      capital_timezone: 'Europe/Belgrade',
      currency: 'Dinar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/srb.png'
    },
    {
      id: 186,
      name: 'Seychelles',
      localname: '',
      alternatives: 'Seychellois',
      iso2: 'SC',
      iso3: 'SYC',
      ison: 690,
      e164: 248,
      fips: 'SE',
      tld: 'sc',
      area: 455,
      phone_code: '248',
      continent: 'Africa',
      capital: 'Victoria',
      capital_timezone: 'Indian/Mahe',
      currency: 'Rupee',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/syc.png'
    },
    {
      id: 187,
      name: 'Sierra Leone',
      localname: '',
      alternatives: 'Sierra Leonean',
      iso2: 'SL',
      iso3: 'SLE',
      ison: 694,
      e164: 232,
      fips: 'SL',
      tld: 'sl',
      area: 71740,
      phone_code: '232',
      continent: 'Africa',
      capital: 'Freetown',
      capital_timezone: 'Africa/Freetown',
      currency: 'Leone',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sle.png'
    },
    {
      id: 188,
      name: 'Singapore',
      localname: '',
      alternatives: 'Singaporean',
      iso2: 'SG',
      iso3: 'SGP',
      ison: 702,
      e164: 65,
      fips: 'SN',
      tld: 'sg',
      area: 693,
      phone_code: '65',
      continent: 'Asia',
      capital: 'Singapur',
      capital_timezone: 'Asia/Singapore',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sgp.png'
    },
    {
      id: 189,
      name: 'Slovakia',
      localname: '',
      alternatives: 'Slovak',
      iso2: 'SK',
      iso3: 'SVK',
      ison: 703,
      e164: 421,
      fips: 'LO',
      tld: 'sk',
      area: 48845,
      phone_code: '421',
      continent: 'Europe',
      capital: 'Bratislava',
      capital_timezone: 'Europe/Bratislava',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/svk.png'
    },
    {
      id: 190,
      name: 'Slovenia',
      localname: '',
      alternatives: 'Slovenian, Slovene',
      iso2: 'SI',
      iso3: 'SVN',
      ison: 705,
      e164: 386,
      fips: 'SI',
      tld: 'si',
      area: 20273,
      phone_code: '386',
      continent: 'Europe',
      capital: 'Ljubljana',
      capital_timezone: 'Europe/Ljubljana',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/svn.png'
    },
    {
      id: 191,
      name: 'Solomon Islands',
      localname: '',
      alternatives: 'Solomon Islanders',
      iso2: 'SB',
      iso3: 'SLB',
      ison: 90,
      e164: 677,
      fips: 'BP',
      tld: 'sb',
      area: 28450,
      phone_code: '677',
      continent: 'Oceania',
      capital: 'Honiara',
      capital_timezone: 'Pacific/Guadalcanal',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/slb.png'
    },
    {
      id: 192,
      name: 'Somalia',
      localname: '',
      alternatives: 'Somali',
      iso2: 'SO',
      iso3: 'SOM',
      ison: 706,
      e164: 252,
      fips: 'SO',
      tld: 'so',
      area: 637657,
      phone_code: '252',
      continent: 'Africa',
      capital: 'Mogadishu',
      capital_timezone: 'Africa/Mogadishu',
      currency: 'Shilling',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/som.png'
    },
    {
      id: 193,
      name: 'South Africa',
      localname: '',
      alternatives: 'South African',
      iso2: 'ZA',
      iso3: 'ZAF',
      ison: 710,
      e164: 27,
      fips: 'SF',
      tld: 'za',
      area: 1219912,
      phone_code: '27',
      continent: 'Africa',
      capital: 'Pretoria',
      capital_timezone: 'Africa/Johannesburg',
      currency: 'Rand',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/zaf.png'
    },
    {
      id: 194,
      name: 'South Georgia and the South Sandwich Islands',
      localname: '',
      alternatives: 'South Georgia, South Sandwich Islands',
      iso2: 'GS',
      iso3: 'SGS',
      ison: 239,
      e164: 500,
      fips: 'SX',
      tld: 'gs',
      area: 3903,
      phone_code: '',
      continent: 'Antarctica',
      capital: 'Grytviken',
      capital_timezone: 'Atlantic/South_Georgia',
      currency: 'Pound',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sgs.png'
    },
    {
      id: 195,
      name: 'South Korea',
      localname: '',
      alternatives: 'South Korean',
      iso2: 'KR',
      iso3: 'KOR',
      ison: 410,
      e164: 82,
      fips: 'KS',
      tld: 'kr',
      area: 98480,
      phone_code: '82',
      continent: 'Asia',
      capital: 'Seoul',
      capital_timezone: 'Asia/Seoul',
      currency: 'Won',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/kor.png'
    },
    {
      id: 196,
      name: 'Spain',
      localname: '',
      alternatives: 'Spanish',
      iso2: 'ES',
      iso3: 'ESP',
      ison: 724,
      e164: 34,
      fips: 'SP',
      tld: 'es',
      area: 504782,
      phone_code: '34',
      continent: 'Europe',
      capital: 'Madrid',
      capital_timezone: 'Europe/Madrid',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/esp.png'
    },
    {
      id: 197,
      name: 'Sri Lanka',
      localname: '',
      alternatives: 'Sri Lankan',
      iso2: 'LK',
      iso3: 'LKA',
      ison: 144,
      e164: 94,
      fips: 'CE',
      tld: 'lk',
      area: 65610,
      phone_code: '94',
      continent: 'Asia',
      capital: 'Colombo',
      capital_timezone: 'Asia/Colombo',
      currency: 'Rupee',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/lka.png'
    },
    {
      id: 198,
      name: 'Sudan',
      localname: '',
      alternatives: 'Sudanese',
      iso2: 'SD',
      iso3: 'SDN',
      ison: 729,
      e164: 249,
      fips: 'SU',
      tld: 'sd',
      area: 1861484,
      phone_code: '249',
      continent: 'Africa',
      capital: 'Khartoum',
      capital_timezone: 'Africa/Khartoum',
      currency: 'Pound',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sdn.png'
    },
    {
      id: 199,
      name: 'Suriname',
      localname: '',
      alternatives: 'Surinamese',
      iso2: 'SR',
      iso3: 'SUR',
      ison: 740,
      e164: 597,
      fips: 'NS',
      tld: 'sr',
      area: 163270,
      phone_code: '597',
      continent: 'South America',
      capital: 'Paramaribo',
      capital_timezone: 'America/Paramaribo',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sur.png'
    },
    {
      id: 200,
      name: 'Svalbard and Jan Mayen',
      localname: '',
      alternatives: 'Svalbard',
      iso2: 'SJ',
      iso3: 'SJM',
      ison: 744,
      e164: 47,
      fips: 'SV',
      tld: 'sj',
      area: 62049,
      phone_code: '47',
      continent: 'Europe',
      capital: 'Longyearbyen',
      capital_timezone: 'Arctic/Longyearbyen',
      currency: 'Krone',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/sjm.png'
    },
    {
      id: 201,
      name: 'Swaziland',
      localname: '',
      alternatives: 'Swazi',
      iso2: 'SZ',
      iso3: 'SWZ',
      ison: 748,
      e164: 268,
      fips: 'WZ',
      tld: 'sz',
      area: 17363,
      phone_code: '268',
      continent: 'Africa',
      capital: 'Mbabane',
      capital_timezone: 'Africa/Mbabane',
      currency: 'Lilangeni',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/swz.png'
    },
    {
      id: 202,
      name: 'Sweden',
      localname: '',
      alternatives: 'Swedish',
      iso2: 'SE',
      iso3: 'SWE',
      ison: 752,
      e164: 46,
      fips: 'SW',
      tld: 'se',
      area: 449964,
      phone_code: '46',
      continent: 'Europe',
      capital: 'Stockholm',
      capital_timezone: 'Europe/Stockholm',
      currency: 'Krona',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/swe.png'
    },
    {
      id: 203,
      name: 'Switzerland',
      localname: '',
      alternatives: 'Swiss',
      iso2: 'CH',
      iso3: 'CHE',
      ison: 756,
      e164: 41,
      fips: 'SZ',
      tld: 'ch',
      area: 41290,
      phone_code: '41',
      continent: 'Europe',
      capital: 'Berne',
      capital_timezone: 'Europe/Zurich',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/che.png'
    },
    {
      id: 204,
      name: 'Syria',
      localname: '',
      alternatives: 'Syrian',
      iso2: 'SY',
      iso3: 'SYR',
      ison: 760,
      e164: 963,
      fips: 'SY',
      tld: 'sy',
      area: 185180,
      phone_code: '963',
      continent: 'Asia',
      capital: 'Damascus',
      capital_timezone: 'Asia/Damascus',
      currency: 'Pound',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/syr.png'
    },
    {
      id: 205,
      name: 'Taiwan',
      localname: '',
      alternatives: 'Taiwanese',
      iso2: 'TW',
      iso3: 'TWN',
      ison: 158,
      e164: 886,
      fips: 'TW',
      tld: 'tw',
      area: 35980,
      phone_code: '886',
      continent: 'Asia',
      capital: 'Taipei',
      capital_timezone: 'Asia/Taipei',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/twn.png'
    },
    {
      id: 206,
      name: 'Tajikistan',
      localname: '',
      alternatives: 'Tajikistani',
      iso2: 'TJ',
      iso3: 'TJK',
      ison: 762,
      e164: 992,
      fips: 'TI',
      tld: 'tj',
      area: 143100,
      phone_code: '992',
      continent: 'Asia',
      capital: 'Dushanbe',
      capital_timezone: 'Asia/Dushanbe',
      currency: 'Somoni',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tjk.png'
    },
    {
      id: 207,
      name: 'Tanzania',
      localname: '',
      alternatives: 'Tanzanian',
      iso2: 'TZ',
      iso3: 'TZA',
      ison: 834,
      e164: 255,
      fips: 'TZ',
      tld: 'tz',
      area: 945087,
      phone_code: '255',
      continent: 'Africa',
      capital: 'Dodoma',
      capital_timezone: 'Africa/Dar_es_Salaam',
      currency: 'Shilling',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tza.png'
    },
    {
      id: 208,
      name: 'Thailand',
      localname: '',
      alternatives: 'Thai',
      iso2: 'TH',
      iso3: 'THA',
      ison: 764,
      e164: 66,
      fips: 'TH',
      tld: 'th',
      area: 514000,
      phone_code: '66',
      continent: 'Asia',
      capital: 'Bangkok',
      capital_timezone: 'Asia/Bangkok',
      currency: 'Baht',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tha.png'
    },
    {
      id: 209,
      name: 'Togo',
      localname: '',
      alternatives: 'Togolese',
      iso2: 'TG',
      iso3: 'TGO',
      ison: 768,
      e164: 228,
      fips: 'TO',
      tld: 'tg',
      area: 56785,
      phone_code: '228',
      continent: 'Africa',
      capital: 'Lome',
      capital_timezone: 'Africa/Lome',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tgo.png'
    },
    {
      id: 210,
      name: 'Tokelau',
      localname: '',
      alternatives: 'Tokelauan',
      iso2: 'TK',
      iso3: 'TKL',
      ison: 772,
      e164: 690,
      fips: 'TL',
      tld: 'tk',
      area: 10,
      phone_code: '690',
      continent: 'Oceania',
      capital: '',
      capital_timezone: 'Pacific/Fakaofo',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tkl.png'
    },
    {
      id: 211,
      name: 'Tonga',
      localname: '',
      alternatives: 'Tongan',
      iso2: 'TO',
      iso3: 'TON',
      ison: 776,
      e164: 676,
      fips: 'TN',
      tld: 'to',
      area: 748,
      phone_code: '676',
      continent: 'Oceania',
      capital: "Nuku'alofa",
      capital_timezone: 'Pacific/Tongatapu',
      currency: "Pa'anga",
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ton.png'
    },
    {
      id: 212,
      name: 'Trinidad and Tobago',
      localname: '',
      alternatives: 'Trinidadian, Tobagonian',
      iso2: 'TT',
      iso3: 'TTO',
      ison: 780,
      e164: 1,
      fips: 'TD',
      tld: 'tt',
      area: 5128,
      phone_code: '1-868',
      continent: 'North America',
      capital: 'Port of Spain',
      capital_timezone: 'America/Port_of_Spain',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tto.png'
    },
    {
      id: 213,
      name: 'Tunisia',
      localname: '',
      alternatives: 'Tunisian',
      iso2: 'TN',
      iso3: 'TUN',
      ison: 788,
      e164: 216,
      fips: 'TS',
      tld: 'tn',
      area: 163610,
      phone_code: '216',
      continent: 'Africa',
      capital: 'Tunis',
      capital_timezone: 'Africa/Tunis',
      currency: 'Dinar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tun.png'
    },
    {
      id: 214,
      name: 'Turkey',
      localname: '',
      alternatives: 'Turkish',
      iso2: 'TR',
      iso3: 'TUR',
      ison: 792,
      e164: 90,
      fips: 'TU',
      tld: 'tr',
      area: 780580,
      phone_code: '90',
      continent: 'Asia',
      capital: 'Ankara',
      capital_timezone: 'Europe/Istanbul',
      currency: 'Lira',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tur.png'
    },
    {
      id: 215,
      name: 'Turkmenistan',
      localname: '',
      alternatives: 'Turkmen',
      iso2: 'TM',
      iso3: 'TKM',
      ison: 795,
      e164: 993,
      fips: 'TX',
      tld: 'tm',
      area: 488100,
      phone_code: '993',
      continent: 'Asia',
      capital: 'Ashgabat',
      capital_timezone: 'Asia/Ashgabat',
      currency: 'Manat',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tkm.png'
    },
    {
      id: 216,
      name: 'Turks and Caicos Islands',
      localname: '',
      alternatives: 'Turks, Caicos Island',
      iso2: 'TC',
      iso3: 'TCA',
      ison: 796,
      e164: 1,
      fips: 'TK',
      tld: 'tc',
      area: 430,
      phone_code: '1-649',
      continent: 'North America',
      capital: 'Cockburn Town',
      capital_timezone: 'America/Grand_Turk',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tca.png'
    },
    {
      id: 217,
      name: 'Tuvalu',
      localname: '',
      alternatives: 'Tuvaluan',
      iso2: 'TV',
      iso3: 'TUV',
      ison: 798,
      e164: 688,
      fips: 'TV',
      tld: 'tv',
      area: 26,
      phone_code: '688',
      continent: 'Oceania',
      capital: 'Funafuti',
      capital_timezone: 'Pacific/Funafuti',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/tuv.png'
    },
    {
      id: 218,
      name: 'U.S. Virgin Islands',
      localname: '',
      alternatives: 'U.S. Virgin Islanders',
      iso2: 'VI',
      iso3: 'VIR',
      ison: 850,
      e164: 1,
      fips: 'VQ',
      tld: 'vi',
      area: 352,
      phone_code: '1-340',
      continent: 'North America',
      capital: 'Charlotte Amalie',
      capital_timezone: 'America/St_Thomas',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/vir.png'
    },
    {
      id: 219,
      name: 'Uganda',
      localname: '',
      alternatives: 'Ugandan',
      iso2: 'UG',
      iso3: 'UGA',
      ison: 800,
      e164: 256,
      fips: 'UG',
      tld: 'ug',
      area: 236040,
      phone_code: '256',
      continent: 'Africa',
      capital: 'Kampala',
      capital_timezone: 'Africa/Kampala',
      currency: 'Shilling',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/uga.png'
    },
    {
      id: 220,
      name: 'Ukraine',
      localname: '',
      alternatives: 'Ukrainian',
      iso2: 'UA',
      iso3: 'UKR',
      ison: 804,
      e164: 380,
      fips: 'UP',
      tld: 'ua',
      area: 603700,
      phone_code: '380',
      continent: 'Europe',
      capital: 'Kiev',
      capital_timezone: 'Europe/Kiev',
      currency: 'Hryvnia',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ukr.png'
    },
    {
      id: 221,
      name: 'United Arab Emirates',
      localname: '',
      alternatives: 'Emirati, uae',
      iso2: 'AE',
      iso3: 'ARE',
      ison: 784,
      e164: 971,
      fips: 'AE',
      tld: 'ae',
      area: 82880,
      phone_code: '971',
      continent: 'Asia',
      capital: 'Abu Dhabi',
      capital_timezone: 'Asia/Dubai',
      currency: 'Dirham',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/are.png'
    },
    {
      id: 222,
      name: 'United Kingdom',
      localname: '',
      alternatives: 'British, English, Scot, Scottish, Welsh',
      iso2: 'GB',
      iso3: 'GBR',
      ison: 826,
      e164: 44,
      fips: 'UK',
      tld: 'uk',
      area: 244820,
      phone_code: '44',
      continent: 'Europe',
      capital: 'London',
      capital_timezone: 'Europe/London',
      currency: 'Pound',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/gbr.png'
    },
    {
      id: 223,
      name: 'United States',
      localname: '',
      alternatives: '  US, American',
      iso2: 'US',
      iso3: 'USA',
      ison: 840,
      e164: 1,
      fips: 'US',
      tld: 'us',
      area: 9629091,
      phone_code: '1',
      continent: 'North America',
      capital: 'Washington',
      capital_timezone: 'America/New_York',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/usa.png'
    },
    {
      id: 224,
      name: 'Uruguay',
      localname: '',
      alternatives: 'Uruguayan',
      iso2: 'UY',
      iso3: 'URY',
      ison: 858,
      e164: 598,
      fips: 'UY',
      tld: 'uy',
      area: 176220,
      phone_code: '598',
      continent: 'South America',
      capital: 'Montevideo',
      capital_timezone: 'America/Montevideo',
      currency: 'Peso',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ury.png'
    },
    {
      id: 225,
      name: 'Uzbekistan',
      localname: '',
      alternatives: 'Uzbekistani, Uzbek',
      iso2: 'UZ',
      iso3: 'UZB',
      ison: 860,
      e164: 998,
      fips: 'UZ',
      tld: 'uz',
      area: 447400,
      phone_code: '998',
      continent: 'Asia',
      capital: 'Tashkent',
      capital_timezone: 'Asia/Tashkent',
      currency: 'Som',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/uzb.png'
    },
    {
      id: 226,
      name: 'Vanuatu',
      localname: '',
      alternatives: 'Ni-Vanuatu, Vanuatuan',
      iso2: 'VU',
      iso3: 'VUT',
      ison: 548,
      e164: 678,
      fips: 'NH',
      tld: 'vu',
      area: 12200,
      phone_code: '678',
      continent: 'Oceania',
      capital: 'Port Vila',
      capital_timezone: 'Pacific/Efate',
      currency: 'Vatu',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/vut.png'
    },
    {
      id: 227,
      name: 'Vatican',
      localname: '',
      alternatives: 'Vatican',
      iso2: 'VA',
      iso3: 'VAT',
      ison: 336,
      e164: 39,
      fips: 'VT',
      tld: 'va',
      area: 0,
      phone_code: '379',
      continent: 'Europe',
      capital: 'Vatican City',
      capital_timezone: 'Europe/Vatican',
      currency: 'Euro',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/vat.png'
    },
    {
      id: 228,
      name: 'Venezuela',
      localname: '',
      alternatives: 'Venezuelan',
      iso2: 'VE',
      iso3: 'VEN',
      ison: 862,
      e164: 58,
      fips: 'VE',
      tld: 've',
      area: 912050,
      phone_code: '58',
      continent: 'South America',
      capital: 'Caracas',
      capital_timezone: 'America/Caracas',
      currency: 'Bolivar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ven.png'
    },
    {
      id: 229,
      name: 'Vietnam',
      localname: '',
      alternatives: 'Vietnamese',
      iso2: 'VN',
      iso3: 'VNM',
      ison: 704,
      e164: 84,
      fips: 'VM',
      tld: 'vn',
      area: 329560,
      phone_code: '84',
      continent: 'Asia',
      capital: 'Hanoi',
      capital_timezone: 'Asia/Ho_Chi_Minh',
      currency: 'Dong',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/vnm.png'
    },
    {
      id: 230,
      name: 'Wallis and Futuna',
      localname: '',
      alternatives: 'Wallisian, Futunan',
      iso2: 'WF',
      iso3: 'WLF',
      ison: 876,
      e164: 681,
      fips: 'WF',
      tld: 'wf',
      area: 274,
      phone_code: '681',
      continent: 'Oceania',
      capital: 'Mata Utu',
      capital_timezone: 'Pacific/Wallis',
      currency: 'Franc',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/wlf.png'
    },
    {
      id: 231,
      name: 'Western Sahara',
      localname: '',
      alternatives: 'Sahrawi, Sahrawian, Sahraouian',
      iso2: 'EH',
      iso3: 'ESH',
      ison: 732,
      e164: 212,
      fips: 'WI',
      tld: 'eh',
      area: 266000,
      phone_code: '212',
      continent: 'Africa',
      capital: 'El-Aaiun',
      capital_timezone: 'Africa/El_Aaiun',
      currency: 'Dirham',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/esh.png'
    },
    {
      id: 232,
      name: 'Yemen',
      localname: '',
      alternatives: 'Yemeni',
      iso2: 'YE',
      iso3: 'YEM',
      ison: 887,
      e164: 967,
      fips: 'YM',
      tld: 'ye',
      area: 527970,
      phone_code: '967',
      continent: 'Asia',
      capital: 'Sanaa',
      capital_timezone: 'Asia/Aden',
      currency: 'Rial',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/yem.png'
    },
    {
      id: 233,
      name: 'Zambia',
      localname: '',
      alternatives: 'Zambian',
      iso2: 'ZM',
      iso3: 'ZMB',
      ison: 894,
      e164: 260,
      fips: 'ZA',
      tld: 'zm',
      area: 752614,
      phone_code: '260',
      continent: 'Africa',
      capital: 'Lusaka',
      capital_timezone: 'Africa/Lusaka',
      currency: 'Kwacha',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/zmb.png'
    },
    {
      id: 234,
      name: 'Zimbabwe',
      localname: '',
      alternatives: 'Zimbabwean',
      iso2: 'ZW',
      iso3: 'ZWE',
      ison: 716,
      e164: 263,
      fips: 'ZI',
      tld: 'zw',
      area: 390580,
      phone_code: '263',
      continent: 'Africa',
      capital: 'Harare',
      capital_timezone: 'Africa/Harare',
      currency: 'Dollar',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/zwe.png'
    },
    {
      id: 235,
      name: 'Israel',
      localname: '',
      alternatives: 'State of Israel, Israeli',
      iso2: 'IL',
      iso3: 'ISR',
      ison: 376,
      e164: 972,
      fips: 'IL',
      tld: 'il',
      area: 20770,
      phone_code: '972',
      continent: 'Asia',
      capital: 'Jerusalem',
      capital_timezone: '',
      currency: '',
      created: '2022-05-31 08:24:09',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/isr.png'
    },
    {
      id: 236,
      name: 'Republic of Kosovo',
      localname: 'Republika e Kosovës',
      alternatives: 'Kosovo, Pristina, Република Косово, Kosovar',
      iso2: 'XK',
      iso3: 'KOS',
      ison: 780,
      e164: 381,
      fips: 'KV',
      tld: 'rs',
      area: 10908,
      phone_code: '381',
      continent: 'Europe',
      capital: 'Pristina',
      capital_timezone: '',
      currency: 'Euro',
      created: '2022-05-31 08:24:09',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/kos.png'
    },
    {
      id: 237,
      name: 'South Sudan',
      localname: '',
      alternatives: 'South Sudanese',
      iso2: 'SS',
      iso3: 'SSD',
      ison: 728,
      e164: 211,
      fips: 'SS',
      tld: 'ss',
      area: 619745,
      phone_code: '211',
      continent: 'Africa',
      capital: 'Juba',
      capital_timezone: 'Africa/Juba',
      currency: 'Pound',
      created: '2016-01-11 16:17:33',
      updated: '2022-05-31 08:24:09',
      flag: 'https://library.micetribe.com/flags/ssd.png'
    }
  ]
}
