export const dhedan_event_custom_bracelet = (settings, qrcode) => (
    {
    card: `
    <link href="https://fonts.cdnfonts.com/css/montserrat" rel="stylesheet">
      <section class="print">
    <div class="print-grid">
        <div class="print-zone">
            <div class="bracelet">
                <div class="divider leftspace"></div>

                <div class="code">
                    <div class="barcode">
                    <div class="qr-code">${qrcode.innerHTML}</div>
                        <p class="qrcode">#_BARCODE_#</p>
                    </div>
                </div>
                <!-- logo -->

                <div class="logo">
                    <img
                    class="img-logo"
                            src="https://library.micetribe.com/images/dhedan_logo.png"
                            alt=""
                    />
                </div>

                <!-- zone access -->
                <div class="zone_access">
                    <div class="persona">#_PERSONA_#</div>
                </div>

                <!-- date here  -->
                <div style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;">
                       #_DTCM_BARCODE_IMAGE_# 
                       <div class="dtcm-text">#_DTCM_BARCODE_#</div>
                       </div>

                <div class="code">
                    <div class="barcode">
                        <!-- Change QR Code here -->
                        <div class="qr-code">${qrcode.innerHTML}</div>
                        <p class="qrcode">#_BARCODE_#</p>
                    </div>
                </div>
                <div class="divider"></div>
            </div>
        </div>
    </div>
  </section>
    `,
    style: `
      <style>
        @media print {
          @page {
            size: 20mm 135mm;
          }
          * {
            visibility: hidden;
            border-radius: 0;
          }
          .print,
          .print * {
            visibility: visible;
          }
          .print {
            margin-left: 5.8cm;
            margin-top: 11cm;
          }
          .page-break {
            display: block;
            page-break-before: always
          }
        }
        .page-break {
            display: block;
            page-break-before: always
        }
       
        section.print * {
            margin: 0;
        }
  
        section.print .print-grid .print-zone {
          color: #000;
          height: 20mm;
          width: 135mm;
          margin-top: 68mm;
          border-radius: 0;
          transform-origin: 0 25mm;
          transform: rotateZ(90deg);
        }
  
        section.print .print-grid .print-zone .bracelet {
          height: 20mm;
          width: 135mm;
  
          display: flex;
          align-items: center;
          gap: 6.5mm;
          justify-content: center;
        }
  
        section.print .print-grid .print-zone .bracelet > * {
            display: inline-block;
            vertical-align: middle;
        }
  
        section.print .print-grid .print-zone .bracelet .title {
            width: 55mm;
            height: 25mm;
        }
        section.print .print-grid .print-zone .bracelet .logo {
          width: 55mm;
        }
        section.print .print-grid .print-zone .bracelet .img-logo {
          width: 100%;
          height: 22mm;
          object-fit: contain;
        }
  
        section.print .print-grid .print-zone .bracelet .code .barcode {
            display: block;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
  
        section.print .print-grid .print-zone .bracelet .code .barcode img {
            display: inline-block;
            width: 20mm;
            height: 20mm;
        }
  
        section.print .print-grid .print-zone .bracelet .code .barcode span {
            display: inline-block;
            writing-mode: vertical-lr;
            text-orientation: sideways;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
  
        section.print .print-grid .print-zone .bracelet .divider {
            height: 25mm;
            width: 6px;
            background-color: black;
        }
  
        .leftspace {
          margin-left: -30mm !important
        }
  
        section.print .print-grid .print-zone .bracelet .logo {
            /* margin: 0 20px; */
        }
  
        section.print .print-grid .print-zone .bracelet .zone_access {
            text-align: center;
        }
  
        section.print .print-grid .print-zone .bracelet .zone_access h5 {
            margin-bottom: 5px;
            text-align: center;
        }
  
        section.print .print-grid .print-zone .bracelet #date {
            font-weight: bold;
            /* margin: 0 20px; */
        }
  
        section.print .print-grid .print-zone .bracelet .code p {
        }
  
        section.print .print-grid .print-zone .bracelet .code p span {
            display: block;
            text-align: left;
            white-space: nowrap;
        }
  
        section.print .print-grid .print-zone .bracelet .partner img {
            height: 25mm;
            width: 36mm;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm {
            page-break-after: always;
            height: 25mm;
            margin-left: 4mm;
            margin-top: -10px;
        }

  
        section.print .print-grid .print-zone .bracelet .dtcm .order .left {
            text-align: left;
            float: left;
            font-weight: 900;
            display: inline-block;
            font-size: 8px;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm .order .right {
            font-size: 8px;
            text-align: right;
            float: right;
            display: inline-block;
            font-weight: bold;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm img {
            width: 3cm;
            height: 9mm;
            border-radius: 0;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm .complimentary {
            font-size: 8px;
            display: inline-block;
            font-weight: bold;
            text-align: left;
            width: 1.5cm;
            float: left;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm .category {
            display: inline-block;
            font-size: 8px;
            font-weight: bold;
            text-align: right;
            width: 1.5cm;
            float: right;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm .barcode {
            font-family: 'Montserrat', sans-serif;
            "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
            width: 3cm;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm .barcode .left {
            text-align: left;
            float: left;
            display: inline-block;
            font-size: 10px;
            line-height: 10px;
        }
  
        section.print .print-grid .print-zone .bracelet .dtcm .barcode .right {
            font-size: 8px;
            line-height: 8px;
            text-align: right;
            float: right;
            display: inline-block;
            font-weight: bold;
        }
  
        .qrcode {
            display: inline-block;
            writing-mode: vertical-lr;
            text-orientation: sideways;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
            margin: 0;
            font-size: 8px;
        }

        .page-break {
            display: block;
            page-break-before: always
        }
    
        @media print {
          .page-break {
            display: block;
            page-break-before: always
          }
        }
        .dtcm-text{
            font-size: 2mm;
            text-align: center;
        }
        .dtcm-code{
            width: 100%;
            height: 10mm;
            width: 10mm;
        }
        .persona{
            width: 33mm;
            max-width: 33mm;
        }
      </style>
    `
  })