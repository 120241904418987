<mat-form-field appearance="outline">
  <mat-label [innerHtml]="control.field.label"></mat-label>
  <input
    matInput
    type="text"
    autocomplete="disabled"
    [formControl]="control"
    (input)="predictInput()"
    (keydown.ArrowRight)="applyPrediction($event)"
    (keydown.enter)="applyPrediction($event)"
    (keydown.tab)="applyPrediction($event)"
  />
  <span class="prediction-text" [ngStyle]="{ left: calculateLeftPosition() + 'px' }">{{ prediction }}</span>
  <mat-hint>
    <mice-input-shortcuts></mice-input-shortcuts>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
