export const earthna_qnd24_emc = (settings, qrcode) => ({
  card: `
    <div class="badge-container">
    <div class="info-container">
        <div class="wrapper">
        <p class="organizer">#_PERSONA_#</p>
            <div id="fullnameWrapper">
            <p id="fullnameText" class="fullname">#_FULLNAME_#</p>
            </div>
             <div id="organizationWrapper">
            <p id="organization" class="organization">#_ORGANIZATION_#</p>
            </div>
             <div class="qr-code">
                <div class="qrcode">
                ${`<div class="qr-code">${qrcode.innerHTML}</div>`}
                </div>
            </div>
        </div>
    </div>
</div>
<script>
                function checkOverflow() {
                    const div = document.getElementById('meetings');
                    const text = document.getElementById('meetings_text');

                    const fullnameWrapper = document.getElementById('fullnameWrapper');
                    const fullnameText = document.getElementById('fullnameText');

                    const organizationWrapper = document.getElementById('organizationWrapper');
                    const organization = document.getElementById('organization');

                    if (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(text).fontSize);
                        while (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                            fontSize -= 1;
                            text.style.fontSize = fontSize + 'px';
                        }
                    }

                    if (fullnameText.offsetHeight > fullnameWrapper.offsetHeight || fullnameText.offsetWidth > fullnameWrapper.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(fullnameText).fontSize);
                        while (fullnameText.offsetHeight > fullnameWrapper.offsetHeight || fullnameText.offsetWidth > fullnameWrapper.offsetWidth) {
                            fontSize -= 1;
                            fullnameText.style.fontSize = fontSize + 'px';
                        }
                    }

                    if (organization.offsetHeight > organizationWrapper.offsetHeight || organization.offsetWidth > organizationWrapper.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(organization).fontSize);
                        while (organization.offsetHeight > organizationWrapper.offsetHeight || organization.offsetWidth > organizationWrapper.offsetWidth) {
                            fontSize -= 1;
                            organization.style.fontSize = fontSize + 'px';
                        }
                    }
                }
                window.addEventListener('load', checkOverflow);
                window.addEventListener('resize', checkOverflow);
                </script>
    `
  ,
  style: `
      <style>
      * {
      font-family: 'D-DIN', sans-serif;
      border-radius: 0;
      margin: 0;
      padding: 0;
      font-weight: 400;
      visibility: visible;
      color: #000;
  }
  
  .wrapper {
      text-align: center;
      padding: 0 2mm;
      height: 52mm;
    }
  
  .mb-2 {
      margin-bottom: 2px !important;
  }
  
  .badge-container {
      width: 81mm !important;
      height: 126mm !important;
      margin-right: auto !important;
      margin-left: auto !important;
      text-align: center !important;
      transform: rotateZ(180deg) !important;
  }
  
  .info-container {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 42mm !important;
  }
  
  
  
  .fullname {
      padding-top: 2mm;
      font-size: 4.5mm;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: uppercase;
  }
  
  .organization {
      padding-top: 2mm;
      font-size: 4.5mm;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: uppercase;
  }

  #organizationWrapper{
    max-height: 16mm;
  }
 
  .organizer {
      padding-top: 3mm;
      font-size: 9.5mm !important;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: uppercase;
      padding-bottom: 1mm;
  }
  
  .qr-text {
      text-transform: uppercase !important;
      margin: 0 !important;
      overflow: hidden !important;
      font-weight: 400 !important;
      color: #000000 !important;
  }
  
  .qr-code {
      width: 18mm !important;
      margin: auto;
  }
  
  .print-page {
      display: block
  }
  
  .page-break {
      display: block;
      page-break-before: always
  }
  
  @media print {
      .page-break {
          display: block;
          page-break-before: always
      }
  }
    @font-face {
        font-family: 'D-DIN';
        src: url('https://library.micetribe.com/fonts/D-DIN.ttf') format('truetype');
    }
      </style>
      `
})
